import { forwardRef } from 'react'
import InputError from './InputError'
import { textInputStyles } from './styles'
import { TextAreaProps } from './types'
import clsx from 'clsx'
import { cn } from '@/utils/styles'

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
    function TextArea(
        {
            name,
            value,
            placeholder,
            label,
            rows,
            cols,
            className,
            required,
            onChange,
            error,
            disabled,
            ...rest
        },
        ref
    ) {
        let labelText
        if (label) labelText = required ? `${label} *` : label
        return (
            <>
                {!!label && <label htmlFor={name}>{labelText}</label>}
                <textarea
                    {...rest}
                    name={name}
                    placeholder={placeholder}
                    rows={rows}
                    cols={cols}
                    value={value}
                    required={required}
                    disabled={disabled}
                    className={cn(
                        textInputStyles,
                        className,
                        disabled && 'bg-gray-light text-gray-600'
                    )}
                    onChange={onChange}
                    ref={ref}
                />
                <InputError error={error} />
            </>
        )
    }
)
