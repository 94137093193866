export function generateSlug(productName: string) {
    // Convert the product name to lowercase
    let slug = productName.toLowerCase()

    // Remove special characters, punctuation, and spaces
    slug = slug.replace(/[^\w\s-]/g, '')

    // Replace spaces with hyphens
    slug = slug.replace(/\s+/g, '-')

    // Trim hyphens from the beginning and end
    slug = slug.replace(/^-+|-+$/g, '')

    return slug
}

export function makeProductDetailUrl(name: string, id: string) {
    return `/eshop/product/${generateSlug(name)}/${id}`
}
