import FormComponents from '@/components/FormComponents'
import { TextInputProps } from '@/components/FormComponents/types'
import clsx from 'clsx'

const { TextInput } = FormComponents

interface Props extends TextInputProps {
    size?: 'md' | 'sm'
}
export default function EshopTextInput({
    className,
    size = 'md',
    ...rest
}: Props) {
    return (
        <TextInput
            {...rest}
            className={clsx(
                'border-1 rounded-lg',
                size === 'md' ? 'py-4' : 'py-2',
                className
            )}
        />
    )
}
