import { forwardRef, Fragment } from 'react'
import InputError from './InputError'
import { RadioProps } from './types'

export const Radio = forwardRef<HTMLInputElement, RadioProps>(function Radio(
    { title, options, onChange, error, ...rest },
    ref
) {
    return (
        <div onChange={onChange}>
            <p>{title}</p>
            {!!options &&
                options.map((option) => (
                    <Fragment key={option.value}>
                        <label htmlFor={option.name} />
                        <input
                            {...rest}
                            type="radio"
                            className="ml-2"
                            name={option.name}
                            value={option.value}
                            id={option.value}
                            ref={ref}
                        />{' '}
                        {option.label}
                    </Fragment>
                ))}
            <InputError error={error} />
        </div>
    )
})
