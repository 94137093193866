'use client'
import userIcon from '../../../public/user.svg'
import logoutIcon from '../../../public/logout.svg'
import Image from 'next/image'
import Link from 'next/link'
import { useUser } from '@/eshop/hooks/useUser'
import { useCallback, useContext } from 'react'
import { logoutUser } from '@/eshop/queries'
import { removeTokenFromCookie } from '@/eshop/utils/client'
import { EshopContext } from '../EshopProvider'
import clsx from 'clsx'
import { EshopRoutes } from '@/eshop/routes'
import { useRouter } from 'next/navigation'
import { User } from '@/eshop/utils/schema'

interface Props {
    className?: string
    onClick?: () => void
}

export function NavbarUser({ className, onClick }: Props) {
    const user = useUser()
    const { setShowLoginModal, setUser } = useContext(EshopContext)
    const { push, refresh } = useRouter()

    const handleLogout = useCallback(() => {
        if (!user) {
            return
        }

        push('/eshop')
        logoutUser().then(() => {
            setUser(null)
            removeTokenFromCookie()
            refresh()
            setTimeout(() => {
                window?.location?.reload()
            }, 250)
        })
    }, [user, push, refresh])

    const handleLoginClick = useCallback(
        (e: React.MouseEvent<HTMLAnchorElement>) => {
            if (!user) {
                e.preventDefault()
                setShowLoginModal('login')
                onClick?.()
            }
        },
        [user, setShowLoginModal, onClick]
    )

    return (
        <div className={clsx('flex items-center', className)}>
            <Link
                className="flex items-center tooltip tooltip-bottom"
                data-tip="Dashboard"
                href={!!user ? EshopRoutes.user : '/eshop/login'}
                onClick={handleLoginClick}
                data-testid="loginLink"
            >
                <Image src={userIcon} alt="User" className="mr-2" />
                {user ? getUserName(user) : 'Log in'}
            </Link>
            {!!user && (
                <div className="tooltip tooltip-bottom ml-4" data-tip="Log out">
                    <Image
                        onClick={handleLogout}
                        src={logoutIcon}
                        alt="logout"
                        className="cursor-pointer"
                    />
                </div>
            )}
        </div>
    )
}

const MAX_NAME_LENGTH = 12
function sliceName(name: string) {
    if (name.length <= MAX_NAME_LENGTH) {
        return name
    }

    return name.slice(0, MAX_NAME_LENGTH) + '...'
}

function getUserName(user: User) {
    if (user?.info?.name) {
        const name = [user.info.name, user.info.surname].join(' ')
        if (name.length > MAX_NAME_LENGTH) {
            return `${user.info.name[0]}. ${sliceName(user.info.surname)}`
        }
        return name
    }
    return sliceName(user.name)
}
