import { DEFAULT_LANGUAGE, LANGUAGE_STORAGE_KEY } from '@/constants'
import { ReadonlyRequestCookies } from 'next/dist/server/web/spec-extension/adapters/request-cookies'
import { z } from 'zod'
import { parseSchemaToResult, requestToResult } from './request'

export const languageSchema = z.enum(['cs', 'en', 'hu'])
export type Language = z.infer<typeof languageSchema>

export function getLanguageFromCookie(cookieStore: ReadonlyRequestCookies) {
    return parseSchemaToResult(
        languageSchema,
        cookieStore.get(LANGUAGE_STORAGE_KEY)?.value
    ).match({
        Ok: (v) => v,
        Error: () => DEFAULT_LANGUAGE as Language,
    })
}

export async function fetchTranslations(language: Language) {
    const path: string =
        DEFAULT_LANGUAGE === 'cs'
            ? '/lokalize/project/lachner/'
            : '/lokalize/project/reanallabor/'
    return (
        await requestToResult(
            () =>
                fetch(process.env.CMS_API_URL + path + language, {
                    headers: {
                        authorization: `Bearer ${process.env.CMS_API_TOKEN}`,
                    },
                }).then((res) => res.json()),
            'Fetching translations'
        )
    ).match({
        Ok: (d) => {
            return d
        },
        Error: (e) => {
            console.log(
                'ERROR: cannot obtain translation for language',
                language,
                e
            )
            return {}
        },
    })
}
