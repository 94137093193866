import { Button } from '@/components/Button'
import { PropsWithChildren } from 'react'

interface Props {
    disabled?: boolean
}
export function EshopFormButton({
    children,
    disabled,
}: PropsWithChildren<Props>) {
    return (
        <Button
            rootClasses="text-base"
            color="secondary"
            type="wide"
            buttonType="submit"
            disabled={disabled}
            testId="loginButton"
        >
            {children}
        </Button>
    )
}
