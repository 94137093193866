import { Button } from '../Button'
import { SubmitProps } from './types'

export const Submit = ({ text, variant }: SubmitProps) => {
    if (variant !== 'outline') variant = 'primary'

    return (
        // <input type='submit' value={text}/>
        <Button
            color={variant === 'primary' ? 'primary' : 'secondary'}
            outline={variant === 'outline'}
            buttonType="submit"
        >
            {text || 'Submit'}
        </Button>
    )
}
