import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export type SchemaTranslations = ReturnType<typeof useSchemaTranslations>

export function useSchemaTranslations() {
    const { t } = useTranslation()

    return useMemo(() => {
        return {
            required: t('eshop.schema.required'),
            email: t('eshop.schema.email'),
            phone: t('eshop.schema.phone'),
            pwdChars: t('eshop.schema.pwdMinChars'),
            pwdUpper: t('eshop.schema.pwdUpperCaseLetter'),
            pwdSpecial: t('eshop.schema.pwdSpecial'),
            pwdNumber: t('eshop.schema.pwdNumber'),
        } as const
    }, [t])
}
