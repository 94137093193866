import { request } from 'graphql-request'
import {
    BatchK2Page,
    CategoriesConnection,
    CategoryFilter,
    CategorySort_Field,
    ChangePasswordInput,
    ChangePasswordMessageInput,
    ChangePasswordMessageResult,
    ChangePasswordResult,
    ContactCreateInput,
    ContactCreateOrUpdate,
    ContactK2Page,
    ContactUpdateInput,
    CountriesConnection,
    DeliveryAndPaymentInput,
    DeliveryAndPaymentResult,
    DocumentResult,
    InferiorOrderFilter,
    InferiorOrderK2Page,
    InferiorUserK2Page,
    InvoiceK2Page,
    OrderApproveInput,
    OrderApproveResult,
    OrderCreateInput,
    OrderCreateResult,
    OrderDocumentInput,
    OrderK2Page,
    OrderStatusUpdate,
    OrderStatusUpdateInput,
    PriceInput,
    PriceResult,
    PricesAndAvailabilityInput,
    PricesAndAvailabilityResult,
    Product,
    ProductAvailabilityInput,
    ProductAvailabilityResult,
    ProductDocumentInput,
    ProductGroupsConnection,
    ProductsConnection,
    ProductSort_Field,
    RootQueryProductGroupsArgs,
    RootQueryProductsArgs,
    SearchProductFilter,
    SearchProductsConnection,
    SortOrder,
    TransportCostInput,
    TransportCostResult,
    User,
    UserBlockInput,
    UserBlockResult,
    UserCreateBusinessInput,
    UserCreateResult,
    UserUnblockInput,
    UserUnblockResult,
} from './gql/graphql'
import { requestToFuture, requestToResult } from '@/utils/request'
import { ClientError } from 'graphql-request'

const API_URL = process.env.NEXT_PUBLIC_ESHOP_FE_API_URL || ''

function addTokenToRequest(token?: string | null, useCache = true) {
    if (token) {
        return {
            Authorization: `Bearer ${token}`,
            ...(useCache ? { 'X-Use-GQL-Cache': '1' } : {}),
        }
    }
    return undefined
}

export type ProductGroupsResponse = { productGroups: ProductGroupsConnection }

export function getProductGroupsRequest(
    args: RootQueryProductGroupsArgs,
    token?: string | null
) {
    return request<ProductGroupsResponse>({
        url: API_URL,
        document: `
                query q($first: Int!, $after: String!, $filter: [ProductGroupFilter!], $sort: [ProductGroupSort!]) {
                  productGroups(first: $first, after: $after, filter: $filter, sort: $sort) {
                    totalCount
                    edges {
                        cursor
                        node {
                                id
                                shopRID
                                languageId
                                sortingNameLang
                                importedAt
                                changedAt
                                createdAt
                        }
                    }
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                      startCursor
                      endCursor
                    }
                  }
                }
`,
        variables: args,
        requestHeaders: addTokenToRequest(token),
    })
}

export type ProductsResponse = { products: ProductsConnection }

export function getProductsForGroup(
    args: RootQueryProductsArgs,
    token?: string | null
) {
    return requestToFuture<ProductsResponse, ClientError>(() =>
        request<ProductsResponse>({
            url: API_URL,
            document: `
query q($first: Int!, $after: String!, $filter: [ProductFilter!], $sort: [ProductSort!]) {
  products(first: $first, after: $after, filter: $filter, sort: $sort) {
    totalCount
    edges {
        cursor
        node {
            id
            articleId
            shopRID
            abbr
            vatCoefficient
            CAS
            brandRID
            EINECS
            RTECS
            relMolWeight
            articleCategory
            importedAt
            changedAt
            createdAt
            productPrices {
                id
                articleId
                shopRID
                currency
                basePrice
                basePriceNet
                importedAt
                changedAt
                createdAt
            }
            productLangItems {
                id
                articleId
                meltingPoint
                boilingPoint
                shopRID
                brandName
                languageId
                CFQuality
                unit
                languageTextCalc
                synonymLang
                usebilityLang
                appearanceLang
                meltingPoint
                boilingPoint
                importedAt
                changedAt
                createdAt
            }
            productGroup {
                id
                sortingNameLang
            }
            specifications {
                id
                shopRID
                articleId
                languageId
                url
                changedAt
                createdAt
                importedAt
            }
            safetyDataSheets {
                id
                shopRID
                articleId
                languageId
                url
                changedAt
                createdAt
                importedAt
            }
        }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}
`,
            variables: args,
            requestHeaders: addTokenToRequest(token),
        })
    )
}

export function getProductDetail(
    id: string,
    token: string | null,
    languageId: number
) {
    return requestToResult(() =>
        request<{ product: Product }>({
            url: API_URL,
            document: `
query q($id: Uuid!, $languageId: Int) {
  product(id: $id, languageId: $languageId) {
            id
            articleId
            shopRID
            abbr
            vatCoefficient
            CAS
            EINECS
            RTECS
            relMolWeight
            brandRID
            unitCodeTypeId
            qualityAbbr
            CFSymbols
            CFSentences
            importedAt
            changedAt
            createdAt
            productPrices {
                id
                articleId
                shopRID
                currency
                basePrice
                basePriceNet
                importedAt
                changedAt
                createdAt
            }
            productLangItems {
                id
                articleId
                meltingPoint
                boilingPoint
                shopRID
                languageId
                CFQuality
                unit
                languageTextCalc
                synonymLang
                usebilityLang
                appearanceLang
                brandName
                storageCondLang
                meltingPoint
                boilingPoint
                importedAt
                changedAt
                createdAt
            }
            productGroup {
                id
                shopRID
                languageId
                sortingNameLang
                importedAt
                changedAt
                createdAt
            }
            specifications {
                id
                shopRID
                articleId
                languageId
                url
                changedAt
                createdAt
                importedAt
            }
            safetyDataSheets {
                id
                shopRID
                articleId
                languageId
                url
                changedAt
                createdAt
                importedAt
            }
            images {
                id
                shopRID
                url
            }
  }
}`,
            variables: {
                id,
                languageId,
            },
            requestHeaders: addTokenToRequest(token),
        })
    )
}

export function getCountriesGql(token?: string) {
    return request<{ countries: CountriesConnection }>({
        url: API_URL,
        document: `query q($first: Int!, $after: String!, $filter: [CountryFilter!], $sort: [CountrySort!]) {
  countries(first: $first, after: $after, filter: $filter, sort: $sort) {
    totalCount
    edges {
        node {
            id
            countryId
            name
            countryCode
        }
    }
  }
}`,
        variables: {
            first: 1000,
            after: '',
            filter: [
                {
                    groupAbbr: {
                        eq: 'EU',
                    },
                },
            ],
            sort: [
                {
                    field: 'name',
                    order: 'ASC',
                },
            ],
            before: '',
        },
        requestHeaders: addTokenToRequest(token),
    })
}

export function registerInferiorUser(
    userInput: UserCreateBusinessInput,
    token?: string
) {
    return requestToFuture(() =>
        request<{ userCreateBusiness: UserCreateResult }>({
            url: API_URL,
            document: `
              mutation m($input: UserCreateBusinessInput!) {
                userCreateBusiness(input: $input) {
                  parameters {
                      description
                      name
                      value
                  }
                }
              }
            `,
            variables: { input: userInput },
            requestHeaders: addTokenToRequest(token),
        })
    )
}

export function registerAdminUser(
    userInput: UserCreateBusinessInput,
    token: string
) {
    return request<{ userCreate: UserCreateResult }>({
        url: API_URL,
        document: `
mutation m($input: UserCreateInput!) {
  userCreate(input: $input) {
    parameters {
        description
        name
        value
    }
  }
}
`,
        variables: { input: userInput },
        requestHeaders: addTokenToRequest(token),
    })
}

export function logoutUser() {
    return request<{ userCreateBusiness: UserCreateResult }>({
        url: API_URL,
        document: `
{
  logout {
    result
  }
}
`,
    })
}

export function editUserAddress(token?: string) {
    return (addressInput: string) =>
        requestToResult(() =>
            request<{ status: 'ok' | 'nok' }>({
                url: API_URL,
                document: `
                  mutation m($input: UserCreateInput!) {
                    userCreate(input: $input) {
                      parameters {
                          description
                          name
                          value
                      }
                    }
                  }
`,
                variables: {},
                requestHeaders: addTokenToRequest(token),
            })
        )
}

export function addUserAddress(token?: string) {
    return (addressInput: string) =>
        requestToResult(() =>
            request<{ status: 'ok' | 'nok' }>({
                url: API_URL,
                document: `
                  mutation m($input: UserCreateInput!) {
                    userCreate(input: $input) {
                      parameters {
                          description
                          name
                          value
                      }
                    }
                  }
`,
                variables: {},
                requestHeaders: addTokenToRequest(token),
            })
        )
}

export type UserInfo = { currentUser: User }

export function getCurrentUserInfo(token?: string) {
    return () =>
        requestToFuture(() =>
            request<UserInfo>({
                url: API_URL,
                document: `
query q {
  currentUser {
    name
    surname
    loginNameCalc
    eshopPhoneCalc
    eshopEmailCalc
    companyName
    companyRegNumber
    vatRegNumber
    invoiceAddress {
        name
        street
        completeStreetCalc
        town
        zip
        countryId
        countryAbbr
        companyRegNumber
        email
        phone
        description
        addressId
        recordId
    }
    deliveryAddresses {
            name
            street
            completeStreetCalc
            town
            zip
            countryId
            countryAbbr
            companyRegNumber
            email
            phone
            description
            addressId
            recordId
    }
  }
}
`,
                requestHeaders: addTokenToRequest(token),
            })
        ).toPromise()
}

// TODO: types from gql when available
interface UserDeliveryAddressInput {
    comment: string
    countryId: number
    email: string
    identNumber: string
    name: string
    phone: string
    street: string
    taxIdNo: string
    vatNumber: string
    zip: string
    town: string
}

// TODO: types from gql when available
export function addUserDeliveryAddress(token?: string) {
    return (input: any) =>
        requestToResult(() =>
            request<{ deliveryAddressCreate: { result: boolean } }>({
                url: API_URL,
                document: `
                mutation m($input: DeliveryAddressCreateInput!) {
                    deliveryAddressCreate(input: $input) {
                        result
                    }
                }
`,
                variables: { input } as any,
                requestHeaders: addTokenToRequest(token),
            })
        )
}

// TODO: types from gql when available
export function updateUserDeliveryAddress(token?: string) {
    return (input: any) =>
        requestToResult(() =>
            request<{ deliveryAddressUpdate: { result: boolean } }>({
                url: API_URL,
                document: `
                mutation m($input: DeliveryAddressUpdateInput!) {
                    deliveryAddressUpdate(input: $input) {
                      result
                    }
                  }
`,
                variables: { input } as any,
                requestHeaders: addTokenToRequest(token),
            })
        )
}

export function createContact(token?: string) {
    return (input: ContactCreateInput) =>
        requestToResult(() =>
            request<{ contactCreate: ContactCreateOrUpdate }>({
                url: API_URL,
                document: `
mutation m($input: ContactCreateInput!) {
  contactCreate(input: $input) {
    id
    name
    surname
    companyName
    companyRID
    timeStamp
  }
}
`,
                variables: { input },
                requestHeaders: addTokenToRequest(token),
            })
        )
}

export function updateContact(token?: string) {
    return (input: ContactUpdateInput) =>
        requestToResult(() =>
            request<{ contactUpdate: ContactCreateOrUpdate }>({
                url: API_URL,
                document: `
mutation m($input: ContactUpdateInput!) {
  contactUpdate(input: $input) {
    id
    name
    surname
    companyName
    companyRID
    timeStamp
  }
}
`,
                variables: { input },
                requestHeaders: addTokenToRequest(token),
            })
        )
}

export function getContacts(token?: string) {
    return requestToFuture(() =>
        request<{ contacts: ContactK2Page }>({
            url: API_URL,
            document: `
query q($page: String, $pageSize: Int, $filter: [ContactFilter!], $sort: [ContactSort!]) {
  contacts(page: $page, pageSize: $pageSize, filter: $filter, sort: $sort) {
    firstPage
    nextPage
    previousPage
    lastPage
    pageRecordCount
    records {
        id
        name
        surname
        phone
        email
        exPositionE
        exSuperiorEId
        timeStamp
    }
  }
}
`,
            variables: {
                page: null,
                pageSize: null,
                filter: [{}],
                sort: [
                    {
                        field: 'surname',
                        order: 'ASC',
                    },
                ],
            },
            requestHeaders: addTokenToRequest(token),
        })
    )
}

export function getInferiorUsers(token?: string) {
    return requestToFuture(() =>
        request<{ inferiorUsers: InferiorUserK2Page | null }>({
            url: API_URL,
            document: `
query q($page: String, $pageSize: Int, $filter: [InferiorUserFilter!], $sort: [InferiorUserSort!]) {
  inferiorUsers(page: $page, pageSize: $pageSize, filter: $filter, sort: $sort) {
    firstPage
    nextPage
    previousPage
    lastPage
    pageRecordCount
    records {
        login
        firstName
        surname
        phone
        rid
        statusCodeTypeId
        statusAbbr
    }
  }
}
`,
            variables: {
                page: null,
                pageSize: null,
                filter: [
                    {
                        blocked: {
                            eq: null,
                        },
                    },
                ],
                sort: [
                    {
                        field: 'surname',
                        order: 'ASC',
                    },
                ],
            },
            requestHeaders: addTokenToRequest(token),
        })
    )
}

export function getProductAvailability(token?: string) {
    return (input: ProductAvailabilityInput) =>
        requestToFuture(() =>
            request<{ productAvailabilityResult: ProductAvailabilityResult }>({
                url: API_URL,
                document: `
        query q($input: ProductAvailabilityInput!) {
            productAvailabilityResult(input: $input) {
              parameters {
                  description
                  name
                  value
              }
            }
          }
`,
                variables: {
                    input,
                },
                requestHeaders: addTokenToRequest(token),
            }).then((data) => ({
                ...data,
                articleId: input.goodsId,
            }))
        )
}

export function getProductPrice(token?: string) {
    return (input: PriceInput) =>
        requestToFuture(() =>
            request<{ price: PriceResult }>({
                url: API_URL,
                document: `
query q($input: PriceInput!) {
  price(input: $input) {
    parameters {
        description
        name
        value
    }
  }
}
`,
                variables: {
                    input,
                },
                requestHeaders: addTokenToRequest(token),
            }).then((data) => ({
                ...data,
                articleId: input.articleId,
            }))
        )
}

export function getProductsPricesAndAvailability(token?: string) {
    return (input: PricesAndAvailabilityInput) =>
        requestToFuture(() =>
            request<{ pricesAndAvailability: PricesAndAvailabilityResult }>({
                url: API_URL,
                document: `
        query q($input: PricesAndAvailabilityInput!) {
            pricesAndAvailability(input: $input) {
              products {
                available
                priceNetto
                partnerId
                quality
                onStock
                priceBrutto
                name
                abbr
                sortingName
                color
                colorHexTxt
                brand
                currency
                articleId
              }
              parameters {
                  description
                  name
                  value
              }
            }
          }
`,
                variables: {
                    input,
                },
                requestHeaders: addTokenToRequest(token),
            })
        )
}

export function getUserProductsPricesAndAvailability(token?: string) {
    return (input: PricesAndAvailabilityInput) =>
        requestToFuture(() =>
            request<{ pricesAndAvailabilityUser: PricesAndAvailabilityResult }>(
                {
                    url: API_URL,
                    document: `
                query q($input: PricesAndAvailabilityInput!) {
                    pricesAndAvailabilityUser(input: $input) {
                      products {
                        available
                        priceNetto
                        partnerId
                        quality
                        onStock
                        priceBrutto
                        name
                        abbr
                        sortingName
                        color
                        colorHexTxt
                        brand
                        currency
                        articleId
                      }
                      parameters {
                          description
                          name
                          value
                      }
                    }
                  }
`,
                    variables: {
                        input,
                    },
                    requestHeaders: addTokenToRequest(token),
                }
            )
        )
}

export function getProductDocument(token?: string) {
    return (input: ProductDocumentInput) =>
        requestToFuture(() =>
            request<{ productDocument: DocumentResult }>({
                url: API_URL,
                document: `
        query q($input: ProductDocumentInput!) {
            productDocument(input: $input) {
              resultBase64
            }
          }
`,
                variables: {
                    input,
                },
                requestHeaders: addTokenToRequest(token),
            })
        )
}

export function getDeliveryAndPayment(token?: string) {
    return (input: DeliveryAndPaymentInput, languageId: number) =>
        requestToFuture(() =>
            request<{ deliveryAndPayment: DeliveryAndPaymentResult }>({
                url: API_URL,
                document: `
                query m($input: DeliveryAndPaymentInput!) {
                  deliveryAndPayment(input: $input) {
                    items {
                        RID
                        payment {
                            RID
                            translatedTitleCalc
                            languageTextCalc
                            id
                        }
                        delivery {
                            RID
                            translatedTitleCalc
                            languageTextCalc
                            id
                        }
                    }
                  }
                }
`,
                variables: {
                    input: {
                        ...input,
                        languageId,
                    },
                },
                requestHeaders: addTokenToRequest(token),
            })
        )
}

export function createOrder(token?: string) {
    return (input: OrderCreateInput) =>
        requestToFuture(() =>
            request<{ orderCreate: OrderCreateResult }>({
                url: API_URL,
                document: `
                  mutation m($input: OrderCreateInput!) {
                    orderCreate(input: $input) {
                      RID
                    }
                  }
`,
                variables: {
                    input,
                },
                requestHeaders: addTokenToRequest(token),
            })
        )
}

export function getTransportCost(token?: string) {
    return (input: TransportCostInput) =>
        requestToFuture(() =>
            request<{ transportCost: TransportCostResult }>({
                url: API_URL,
                document: `
                query m($input: TransportCostInput!) {
                    transportCost(input: $input) {
                      parameters {
                          description
                          name
                          value
                      }
                    }
                  }
`,
                variables: {
                    input,
                },
                requestHeaders: addTokenToRequest(token),
            })
        ).mapOk((r) => ({
            ...r,
            transportRID: input.transportId,
        }))
}

export function getMinimalUserInfo(token?: string) {
    return requestToFuture(() =>
        request<{ currentUser: Pick<User, 'name' | 'surname'> }>({
            url: API_URL,
            document: `
            query q {
              currentUser {
                name
                surname
              }
            }
`,
            requestHeaders: addTokenToRequest(token),
        })
    )
}

export function searchProducts(token?: string) {
    return (filter: SearchProductFilter) =>
        requestToFuture(() =>
            request<{ searchProducts: SearchProductsConnection }>({
                url: API_URL,
                document: `
query q($first: Int!, $after: String!, $filter: [SearchProductFilter!]!) {
  searchProducts(first: $first, after: $after, filter: $filter) {
    totalCount
    edges {
        cursor
        node {
            id
            articleId
            shopRID
            abbr
            vatCoefficient
            CAS
            EINECS
            RTECS
            relMolWeight
            articleCategory
            brandRID
            unitCodeTypeId
            qualityAbbr
            CFSymbols
            CFSentences
            importedAt
            changedAt
            createdAt
            productPrices {
                id
                articleId
                shopRID
                currency
                basePrice
                basePriceNet
                importedAt
                changedAt
                createdAt
            }
            productLangItems {
                id
                articleId
                shopRID
                meltingPoint
                boilingPoint
                languageId
                CFQuality
                unit
                languageTextCalc
                synonymLang
                usebilityLang
                appearanceLang
                brandName
                storageCondLang
                meltingPoint
                boilingPoint
                importedAt
                changedAt
                createdAt
            }
            productGroup {
                id
                shopRID
                languageId
                sortingNameLang
                importedAt
                changedAt
                createdAt
            }
            specifications {
                id
                shopRID
                articleId
                languageId
                url
                changedAt
                createdAt
                importedAt
            }
            safetyDataSheets {
                id
                shopRID
                articleId
                languageId
                url
                changedAt
                createdAt
                importedAt
            }
        }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}
`,
                variables: {
                    first: 100,
                    after: '',
                    filter: [filter],
                },
                requestHeaders: addTokenToRequest(token),
            })
        )
}

export function getInferiorOrders(token?: string) {
    return (
        page: string | null,
        pageSize: number,
        filter: InferiorOrderFilter
    ) =>
        requestToFuture(() =>
            request<{ inferiorOrders: InferiorOrderK2Page }>({
                url: API_URL,
                document: `
                query q($page: String, $pageSize: Int, $filter: [InferiorOrderFilter!], $sort: [InferiorOrderSort!]) {
                    inferiorOrders(page: $page, pageSize: $pageSize, filter: $filter, sort: $sort) {
                      firstPage
                      nextPage
                      previousPage
                      lastPage
                      pageRecordCount
                      records {
                          RID
                          currency
                          amountNetC
                          amountGrossCCalc
                          amountVATCCalc
                          contactPersonFullNameTitleCalc
                          deliveryAddressFullNameCalc
                          documentIdentificationCalc
                          fullDeliveryAddressIdCalcOneLineCalc
                          issueDate
                          statusId
                          statusIdAbbr
                          statusIdLanguageTextCalc
                          timeStamp
                          paymentMethodRIDCalcAbbr
                          paymentMethodRIDCalcRID
                          transportMethodRIDCalcAbbr
                          transportMethodRIDCalcRID
                          tradingPartnerId
                          tradingPartnerName
                          tradingPartnerAddressId
                          orderItems{
                              invoiceUnitPriceNetCCalc
                              invoiceUnitPriceGrossCCalc
                              invoiceTotalPriceNetCCalc
                              invoiceUnitPriceGrossCCalc
                              invoiceTotalPriceGrossCCalc
                              invoicePriceVATCalc
                              accountingTotalPriceVATCCalc
                              itemStatus
                              invoiceOutRID
                              quantityUMCalc
                              batchId
                              batchAbbr
                              articleId
                              articleName
                              articleAbbr
                              articleCategoryAbbr
                              articleUnit
                              articleCfQuality
                              articleSortingNameLang
                          }
                      }
                    }
                  }
`,
                variables: {
                    page,
                    pageSize,
                    filter: [filter],
                    sort: [
                        {
                            field: 'issueDate',
                            order: 'DESC',
                        },
                    ],
                },
                requestHeaders: addTokenToRequest(token),
            })
        )
}

export function changeOrderStatus(token?: string) {
    return (input: OrderStatusUpdateInput) =>
        requestToFuture(() =>
            request<{ orderStatusUpdate: OrderStatusUpdate }>({
                url: API_URL,
                document: `
mutation m($input: OrderStatusUpdateInput!) {
  orderStatusUpdate(input: $input) {
    RID
    timeStamp
    statusId
    statusIdAbbr
    description
  }
}
`,
                variables: {
                    input,
                },
                requestHeaders: addTokenToRequest(token),
            })
        )
}

export function getMinimalCurrentUserInfo(token?: string) {
    return () =>
        requestToFuture(() =>
            request<UserInfo>({
                url: API_URL,
                document: `
                  query q {
                    currentUser {
                      name
                      surname
                    }
                  }
`,
                requestHeaders: addTokenToRequest(token),
            })
        ).toPromise()
}

export function blockUser(token?: string) {
    return (input: UserBlockInput) =>
        requestToFuture(() =>
            request<{ userBlock: UserBlockResult }>({
                url: API_URL,
                document: `
mutation m($input: UserBlockInput!) {
  userBlock(input: $input) {
    parameters {
        description
        name
        value
    }
  }
}
`,
                variables: {
                    input,
                },
                requestHeaders: addTokenToRequest(token),
            })
        )
}

export function activateUser(token?: string) {
    return (input: UserUnblockInput) =>
        requestToFuture(() =>
            request<{ userUnblock: UserUnblockResult }>({
                url: API_URL,
                document: `
mutation m($input: UserUnblockInput!) {
  userUnblock(input: $input) {
    parameters {
        description
        name
        value
    }
  }
}
`,
                variables: {
                    input,
                },
                requestHeaders: addTokenToRequest(token),
            })
        )
}

export function getProductsForDocuments(token?: string) {
    return (letter: string, languageId: number) =>
        requestToFuture(() =>
            request<ProductsResponse>({
                url: API_URL,
                document: `
query q($first: Int!, $after: String!, $filter: [ProductFilter!], $sort: [ProductSort!]) {
  products(first: $first, after: $after, filter: $filter, sort: $sort) {
    totalCount
    edges {
        cursor
        node {
            id
            articleId
            shopRID
            abbr
            productLangItems {
                CFQuality
                unit
                languageTextCalc
                synonymLang
                languageId
            }
            productGroup {
                id
                sortingNameLang
            }
            specifications {
                articleId
                languageId
                url
            }
            safetyDataSheets {
                articleId
                languageId
                url
            }
        }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}
`,
                variables: {
                    first: 1000,
                    after: '',
                    filter: [
                        {
                            productGroup_id: {
                                eq: '',
                            },
                            productLangItems_languageId: {
                                eq: languageId,
                            },
                            specifications_languageId: {
                                eqOrNull: languageId,
                            },
                            safetyDataSheets_languageId: {
                                eqOrNull: languageId,
                            },
                            productPrices_currency: {
                                eq: 'Kč',
                            },
                            productGroup_firstLetter: {
                                eq: letter,
                            },
                        },
                    ],
                    sort: [
                        {
                            field: ProductSort_Field.ProductGroupSortingNameLang,
                            order: 'ASC',
                        },
                    ],
                    before: '',
                    last: 10000,
                },
                requestHeaders: addTokenToRequest(token),
            })
        )
}

export function getBatches(token?: string) {
    return (search: string) =>
        requestToFuture(() =>
            request<{ batches: BatchK2Page }>({
                url: API_URL,
                document: `
query q($page: String, $pageSize: Int, $filter: [BatchFilter!], $sort: [BatchSort!]) {
  batches(page: $page, pageSize: $pageSize, filter: $filter, sort: $sort) {
    firstPage
    nextPage
    previousPage
    lastPage
    pageRecordCount
    records {
        id
        abbr
        articleId
        articleName
    }
  }
}
`,
                variables: {
                    page: null,
                    pageSize: 200,
                    filter: [
                        {
                            searchBatch: {
                                like: search,
                            },
                        },
                    ],
                    sort: [
                        {
                            field: 'articleName',
                            order: 'ASC',
                        },
                    ],
                },
                requestHeaders: addTokenToRequest(token),
            })
        )
}

export function getUserOrders(token?: string) {
    return (page: string | null, pageSize: number) =>
        requestToFuture(() =>
            request<{ orders: OrderK2Page }>({
                url: API_URL,
                document: `
query q($page: String, $pageSize: Int, $filter: [OrderFilter!], $sort: [OrderSort!]) {
  orders(page: $page, pageSize: $pageSize, filter: $filter, sort: $sort) {
    firstPage
    nextPage
    previousPage
    lastPage
    pageRecordCount
    records {
        RID
        currency
        amountNetC
        amountGrossCCalc
        amountVATCCalc
        contactPersonFullNameTitleCalc
        deliveryAddressFullNameCalc
        documentIdentificationCalc
        fullDeliveryAddressIdCalcOneLineCalc
        issueDate
        statusId
        statusIdAbbr
        statusIdLanguageTextCalc
        timeStamp
        paymentMethodRIDCalcAbbr
        paymentMethodRIDCalcRID
        transportMethodRIDCalcAbbr
        transportMethodRIDCalcRID
        tradingPartnerId
        tradingPartnerName
        tradingPartnerAddressId
        orderItems{
            invoiceUnitPriceNetCCalc
            invoiceUnitPriceGrossCCalc
            invoiceTotalPriceGrossCCalc
            invoiceTotalPriceNetCCalc
            invoiceUnitPriceGrossCCalc
            invoicePriceVATCalc
            accountingTotalPriceVATCCalc
            itemStatus
            invoiceOutRID
            quantityUMCalc
            batchId
            batchAbbr
            articleId
            articleName
            articleAbbr
            articleCategoryAbbr
            articleUnit
            articleCfQuality
            articleSortingNameLang
        }
    }
  }
}
`,
                variables: {
                    page,
                    pageSize,
                    filter: [
                        {
                            orderStatus: {
                                eq: null,
                            },
                            searchSurname: {
                                like: null,
                            },
                        },
                    ],
                    sort: [
                        {
                            field: 'issueDate',
                            order: 'DESC',
                        },
                    ],
                },
                requestHeaders: addTokenToRequest(token),
            })
        )
}

export function getUserInvoices(token?: string) {
    return (page: string | null, pageSize: number, onlyOverdue?: boolean) =>
        requestToFuture(() =>
            request<{ invoices: InvoiceK2Page }>({
                url: API_URL,
                document: `
                query q($page: String, $pageSize: Int, $filter: [InvoiceFilter!], $sort: [InvoiceSort!]) {
                    invoices(page: $page, pageSize: $pageSize, filter: $filter, sort: $sort) {
                      firstPage
                      nextPage
                      previousPage
                      lastPage
                      pageRecordCount
                      records {
                          RID
                          amountNetC
                          amountGrossC
                          amountVATCCalc
                          referenceNumber
                          documentIdentificationCalc
                          confirmedOrCanceled2IdCalcDescription
                          paidStateIdCalcDescription
                          payOffAmountCCalc
                          taxPointDate
                          dueDate
                      }
                    }
                  }
`,
                variables: {
                    page,
                    pageSize,
                    filter: [
                        {
                            unpaidInvoices: {
                                eq: false,
                            },
                            sentInvoices: {
                                eq: false,
                            },
                            overdueInvoices: {
                                eq: onlyOverdue || false,
                            },
                        },
                    ],
                    sort: [
                        {
                            field: 'taxPointDate',
                            order: 'DESC',
                        },
                        {
                            field: 'dueDate',
                            order: 'ASC',
                        },
                    ],
                },
                requestHeaders: addTokenToRequest(token),
            })
        )
}

export function getOrderDocument(token?: string) {
    return (input: OrderDocumentInput) =>
        requestToFuture(() =>
            request<{ orderDocument: DocumentResult }>({
                url: API_URL,
                document: `
                query q($input: OrderDocumentInput!) {
                    orderDocument(input: $input) {
                      resultBase64
                    }
                  }
`,
                variables: {
                    input,
                },
                requestHeaders: addTokenToRequest(token),
            })
        )
}

export function getCategories(token?: string) {
    return (input: {
        first: number
        after: string
        filter: CategoryFilter[]
    }) =>
        requestToFuture(() =>
            request<{ categories: CategoriesConnection }>({
                url: API_URL,
                document: `
                  query q($first: Int!, $after: String!, $filter: [CategoryFilter!], $sort: [CategorySort!]) {
                    categories(first: $first, after: $after, filter: $filter, sort: $sort) {
                      totalCount
                      edges {
                          cursor
                          node {
                                  id
                                  shopRID
                                  RID
                                  name
                                  categoryPathNameCalc
                                  importedAt
                                  changedAt
                                  createdAt
                                  categoryLangItems {
                                      id
                                      shopRID
                                      languageId
                                      translatedTitleCalc
                                      importedAt
                                  }

                          }
                      }
                      pageInfo {
                        hasNextPage
                        hasPreviousPage
                        startCursor
                        endCursor
                      }
                    }
                  }
`,
                variables: {
                    ...input,
                    sort: [
                        {
                            field: CategorySort_Field.CategoryPathNameCalc,
                            order: SortOrder.Asc,
                        },
                    ],
                    before: '',
                    last: 10000,
                },
                requestHeaders: addTokenToRequest(token),
            })
        )
}

export function changePasswordMessage(token?: string) {
    return (input: ChangePasswordMessageInput) =>
        requestToFuture(() =>
            request<{ changePasswordMessage: ChangePasswordMessageResult }>({
                url: API_URL,
                document: `
        mutation m($input: ChangePasswordMessageInput!) {
          changePasswordMessage(input: $input) {
            result
          }
        }
`,
                variables: { input },
                requestHeaders: addTokenToRequest(token),
            })
        )
}

export function changePassword(token?: string) {
    return (input: ChangePasswordInput) =>
        requestToFuture(() =>
            request<{ changePassword: ChangePasswordResult }>({
                url: API_URL,
                document: `
mutation m($input: ChangePasswordInput!) {
  changePassword(input: $input) {
    parameters {
        description
        name
        value
    }
  }
}
`,
                variables: { input },
                requestHeaders: addTokenToRequest(token),
            })
        )
}

export function approveOrder(token?: string) {
    return (input: OrderApproveInput) =>
        requestToFuture(() =>
            request<{ orderApprove: OrderApproveResult }>({
                url: API_URL,
                document: `
                mutation m($input: OrderApproveInput!) {
                    orderApprove(input: $input) {
                      parameters {
                          description
                          name
                          value
                      }
                    }
                  }
`,
                variables: { input },
                requestHeaders: addTokenToRequest(token),
            })
        )
}
