import { getPasswordValidationT } from '@/utils/schema'
import { z } from 'zod'
import { SchemaTranslations } from '../hooks/useSchemaTranslations'
import { Language } from '@/utils/language'

export const validateFormData = <T>(fd: FormData, schema: z.ZodSchema<T>) => {
    const temp: any = {}
    for (const [k, v] of fd.entries()) {
        temp[k] = v
    }

    return schema.safeParse(temp)
}

export const graphqlErrorResponseShema = z.object({
    code: z.number(),
    message: z.string().optional(),
    badToken: z.boolean().optional(),
})

export type GraphqlErrorResponse = z.infer<typeof graphqlErrorResponseShema>

export function getLoginSchema(t: SchemaTranslations) {
    return z.object({
        email: z.string().email(t.email),
        password: z.string().nonempty(t.required),
    })
}

export type LoginFormData = z.infer<ReturnType<typeof getLoginSchema>>

export function getForgottenPwdSchema(t: SchemaTranslations) {
    return z.object({
        email: z.string().email(t.email),
    })
}

export type ForgottenPwdFormData = z.infer<
    ReturnType<typeof getForgottenPwdSchema>
>

export function getChangePwdSchema(t: SchemaTranslations) {
    return z.object({
        password: getPasswordValidationT(t),
    })
}

export type ChangePwdFormData = z.infer<ReturnType<typeof getChangePwdSchema>>

const makeResponseSchema = <T>(schema: z.Schema<T>) => {
    return z.discriminatedUnion('status', [
        z.object({
            status: z.literal('success'),
            data: schema,
        }),
        z.object({ status: z.literal('failed'), error: z.string().optional() }),
    ])
}

// TODO: use roles
export const userRoles = [
    'ROLE_BUSINESS_USER',
    'ROLE_USER',
    'ROLE_COMPANY_ADMIN',
] as const
export const userRolesSchema = z.enum(userRoles)

export const userSchema = z.object({
    name: z.string(),
    id: z.number().nullable(),
    isAnonymous: z.boolean(),
    tokenExpiring: z.number(),
    roles: z.array(userRolesSchema),
    info: z
        .object({
            name: z.string(),
            surname: z.string(),
        })
        .optional(),
})

export type User = z.infer<typeof userSchema>

export const loginResponseSchema = makeResponseSchema(
    z.object({
        token: z.string(),
        user: userSchema,
    })
)

export type LoginResponse = z.infer<typeof loginResponseSchema>

export function getRegistrationSchema(
    t: SchemaTranslations,
    language: Language
) {
    const registrationSchema = z.object({
        name: z.string().min(3, t.required),
        surname: z.string().min(3, t.required),
        email: z.string().email(t.email),
        password: getPasswordValidationT(t),
        phoneNumber: z.string().nonempty(t.phone),
        VAT: z.string().optional().nullable(),
        firmName: z.string().optional(),
        street: z.string().optional(),
        city: z.string().optional(),
        country: z.string().optional(),
        zip: z.string().optional(),
    })

    type RegistrationSchema = z.infer<typeof registrationSchema>

    return registrationSchema
        .refine(
            (data) => {
                if (language === 'hu' && !data.VAT) {
                    return false
                }
                return true
            },
            (data) => {
                return {
                    message: t.required,
                    path: ['VAT'],
                }
            }
        )
        .refine(
            (data) => {
                return language !== 'hu' || !data.VAT
                    ? true
                    : !!data.firmName &&
                          !!data.street &&
                          !!data.country &&
                          !!data.zip
            },
            (data) => {
                const fields: Array<keyof RegistrationSchema> = [
                    'firmName',
                    'street',
                    'zip',
                    'city',
                    'country',
                ]

                const firstEmptyField = fields.find((f) => !data[f])

                return {
                    message: t.required,
                    path: firstEmptyField ? [firstEmptyField] : [],
                }
            }
        )
}

export type RegistrationSchema = z.infer<
    ReturnType<typeof getRegistrationSchema>
>

export const registrationResponseSchema = makeResponseSchema(
    z.object({
        message: z.string().nullable(),
    })
)

export type RegistrationResponse = z.infer<typeof registrationResponseSchema>

export const tokenUserSchema = z.object({
    exp: z.number(),
    id: z.number().nullable(),
    username: z.string().nullable(),
    roles: z.array(userRolesSchema),
})

export type TokenUser = z.infer<typeof tokenUserSchema>

export const aresSchema = z.object({
    pocetCelkem: z.number(),
    ekonomickeSubjekty: z.array(
        z.object({
            ico: z.string(),
            obchodniJmeno: z.string(),
            sidlo: z.object({
                nazevUlice: z.string(),
                cisloDomovni: z.number(),
                nazevObce: z.string(),
                psc: z.number(),
                textovaAdresa: z.string(),
            }),
            dic: z.string().nullable().optional(),
            icoId: z.string().nullable().optional(),
        })
    ),
})

export const firmResponseSchema = z.object({
    firmName: z.string().optional(),
    street: z.string().optional(),
    city: z.string().optional(),
    zip: z.string().optional(),
})

export type firmResponseSchema = z.infer<typeof firmResponseSchema>
