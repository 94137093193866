'use client'
import { MouseEventHandler, useCallback, useMemo, useRef } from 'react'
import clsx from 'clsx'
import Link from 'next/link'

type Classnames = {
    label?: (baseClassNames: string) => string
    content?: (baseClassNames: string) => string
}

export interface DropdownProps {
    className?: string
    classNames?: Classnames
    labelClassName?: string
    label: string | JSX.Element
    items: Array<{
        label: string
        link?: string
        onClick?: MouseEventHandler
    }>
    activeItemIndex?: number
}

function isElementVisible(el: HTMLElement) {
    const styles = window.getComputedStyle(el)
    return styles.visibility === 'visible' && styles.opacity === '1'
}

export function Dropdown({
    label,
    items,
    activeItemIndex,
    className,
    classNames,
    labelClassName,
}: DropdownProps) {
    const baseLabelClassNames = clsx('dropdown', className)
    const baseContentClassNames =
        'dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52'

    const preparedClassNames = useMemo(
        () => ({
            label: classNames?.label?.(baseLabelClassNames),
            content: classNames?.content?.(baseContentClassNames),
        }),
        [baseLabelClassNames, classNames]
    )

    const labelEl = useRef<HTMLLabelElement>(null)
    const ulEl = useRef<HTMLUListElement>(null)
    const isOpen = useRef(false)

    const handleToggle: MouseEventHandler<HTMLLabelElement> =
        useCallback(() => {
            const isVisible = isElementVisible(ulEl.current!)
            if (isVisible && isOpen.current) {
                isOpen.current = false
                labelEl.current?.blur()
                return
            }
            isOpen.current = true
        }, [])

    const activeItemClassNames = (index: number) =>
        activeItemIndex === index
            ? 'border-b-[1px] border-blue-dark rounded-none'
            : ''

    return (
        <div className={preparedClassNames?.label || baseLabelClassNames}>
            <label
                tabIndex={0}
                className={clsx('btn btn-ghost font-normal', labelClassName)}
                onClick={handleToggle}
                ref={labelEl}
            >
                {label}
            </label>
            <ul
                className={preparedClassNames?.content || baseContentClassNames}
                tabIndex={0}
                ref={ulEl}
            >
                {items.map(({ label, link, onClick }, index) => (
                    <li key={label} className={activeItemClassNames(index)}>
                        <Link
                            href={link || '#'}
                            onClick={(e) => {
                                e.currentTarget.blur()
                                if (onClick) {
                                    onClick(e)
                                }
                            }}
                        >
                            {label}
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    )
}
