const base = '/eshop' as const

export const EshopRoutes = {
    home: `${base}/`,
    user: `${base}/user`,
    userOrders: `${base}/user/orders`,
    userSettings: `${base}/user/settings`,
    inferiorOrders: `${base}/user/inferiororders`,
    invoices: `${base}/user/invoices`,
} as const
