import { useContext, useMemo } from 'react'
import { Language } from '@/utils/language'
import { EshopContext } from '../components/EshopProvider'
import { Currency } from '@/utils/currency'

export const ccToString: Record<Currency, string> = {
    CZK: 'Kč',
    EUR: 'EUR',
    HUF: 'HUF',
}

export const k2CurrencyToCc: Record<string, Currency> = {
    Kč: 'CZK',
    EUR: 'EUR',
    HUF: 'HUF',
}

export const languageToLanguageId: Record<Language, number> = {
    cs: 5,
    en: 1,
    hu: 6,
}

export const languageIdToLanguageCode: Record<number, string> = {
    5: 'cz',
    1: 'gb',
    6: 'hu',
}

export const languageCodeToLanguageName: Record<string, string> = {
    cz: 'eshop.documents.languages.czech',
    en: 'eshop.documents.languages.english',
    gb: 'eshop.documents.languages.english',
    hu: 'eshop.documents.languages.hungary',
}

interface K2Setup {
    languageId: number
    currencyCode: Currency
    currency: string
}

export function useK2Setup() {
    const { lang, currency } = useContext(EshopContext)

    return useMemo(
        (): K2Setup => ({
            languageId: languageToLanguageId[lang] || 5,
            currencyCode: currency,
            currency: ccToString[currency],
        }),
        [lang, currency]
    )
}
