import { Checkbox } from './checkbox'
import Form from './form'
import { Radio } from './radio'
import { Select } from './select'
import { Submit } from './submit'
import { TextArea } from './textArea'
import { TextInput } from './textInput'
const FormComponents = {
    Checkbox,
    Radio,
    Select,
    Submit,
    TextArea,
    TextInput,
    Form,
}

export default FormComponents
