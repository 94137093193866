'use client'
import { Dropdown, DropdownProps } from '@/components/Dropdown'
import {
    MouseEventHandler,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { LANGUAGE_STORAGE_KEY } from '@/constants'
import { useRouter } from 'next/navigation'
import { Language, languageSchema } from '@/utils/language'
import { Option } from '@swan-io/boxed'
import { getCookieOnClient } from '@/utils/cookie'
import { EshopContext } from '@/eshop/components/EshopProvider'

const langToLabel: Record<Language, string> = {
    cs: 'CZ',
    en: 'EN',
    hu: 'HU',
}
interface Props {
    nativeLanguage?: Language
}

export default function LanguageSwitcher({ nativeLanguage }: Props) {
    const { lang, setLang, country } = useContext(EshopContext)
    const mainLang = nativeLanguage
    const router = useRouter()

    const [cookieLang, setCookieLang] = useState<Language | undefined>(
        undefined
    )

    useEffect(() => {
        const l = getCookieOnClient(LANGUAGE_STORAGE_KEY) || nativeLanguage
        if (l) {
            setCookieLang(l as Language)
        }
    }, [nativeLanguage])

    // set cookie on client to avoid default setting on CMS components
    useEffect(() => {
        Option.fromNullable(getCookieOnClient(LANGUAGE_STORAGE_KEY))
            .flatMap((v) => {
                const result = languageSchema.safeParse(v)
                if (result.success) {
                    return Option.Some(result.data)
                }
                return Option.None()
            })
            .match({
                Some: (v) => {
                    setLang(v)
                },
                None: () => {},
            })
    }, [setLang])

    const langData: DropdownProps['items'] = useMemo(() => {
        const createEventHandler =
            (lang: Language): MouseEventHandler =>
            (e) => {
                e.preventDefault()
                e.stopPropagation()
                setLang(lang)
                setCookieLang(lang)

                window.localStorage.setItem(LANGUAGE_STORAGE_KEY, lang)

                fetch('/api/language-cookie', {
                    method: 'POST',
                    body: JSON.stringify({ lang }),
                }).then(() => {
                    router.refresh()
                    window?.location.reload()
                })
            }

        const langs = [
            {
                label: langToLabel.en,
                onClick: createEventHandler('en'),
            },
        ]

        switch (mainLang) {
            case 'cs':
                langs.push({
                    label: langToLabel.cs,
                    onClick: createEventHandler('cs'),
                })
                break

            case 'hu':
                langs.push({
                    label: langToLabel.hu,
                    onClick: createEventHandler('hu'),
                })
                break

            default:
                break
        }
        return langs
    }, [router, setLang])

    return (
        <Dropdown
            className="mr-4 pl-4 border-l border-gray-light2"
            labelClassName="flex flex-nowrap p-2"
            label={
                <>
                    {langToLabel[cookieLang || lang]}
                    <ChevronDownIcon className="w-[16px]" />
                </>
            }
            items={langData}
        />
    )
}
