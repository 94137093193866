import clsx from 'clsx'
import { Children, FormEventHandler, ReactNode } from 'react'

interface FormProps {
    children: ReactNode
    action?: string
    onSubmit?: FormEventHandler
    className?: string
}

const Form = ({ children, action, onSubmit, className }: FormProps) => {
    return (
        <form
            action={action}
            onSubmit={onSubmit}
            method="post"
            className={clsx('flex flex-col p-2 items-stretch', className)}
        >
            {Children.map(children, (child) => child)}
        </form>
    )
}

export default Form
