import { Heading } from '@/components/Heading'
import clsx from 'clsx'

interface Props {
    size?: 'normal' | 'large'
    children: string
    className?: string
}

export default function EshopFormHeading({
    size = 'normal',
    children,
    className,
}: Props) {
    return (
        <Heading
            text={children}
            tag={size === 'normal' ? 'h3' : 'h2'}
            className={clsx('font-bold text-center text-secondary', className)}
        />
    )
}
