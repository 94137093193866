import Link from 'next/link'
import { forwardRef } from 'react'
import InputError from './InputError'
import { basicInputStyles } from './styles'
import { CheckboxProps } from './types'
import clsx from 'clsx'

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
    function Checkbox(
        {
            name,
            required,
            label,
            value,
            checked,
            onChange,
            url,
            error,
            className,
            ...rest
        },
        ref
    ) {
        const labelText = required
            ? `${` ${label}` || ''} *`
            : `${` ${label}` || ''}`
        return (
            <div>
                <input
                    {...rest}
                    type="checkbox"
                    name={name}
                    required={required}
                    value={value}
                    checked={checked}
                    className={clsx(basicInputStyles, className)}
                    onChange={onChange}
                    ref={ref}
                />
                {!!url ? <Link href={url}>{labelText}</Link> : <>{labelText}</>}
                <InputError error={error} />
            </div>
        )
    }
)
