'use client'
import { TextInput } from '@/components/FormComponents/textInput'
import { eshopInputClasses } from '@/eshop/constants'
import {
    ForgottenPwdFormData,
    getForgottenPwdSchema,
} from '@/eshop/utils/schema'
import { useCallback, useState } from 'react'
import EshopFormHeading from '../FormHeading'
import { EshopFormButton } from '../FormButton'
import { useMutation } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormProps } from './shared'
import { changePasswordMessage } from '@/eshop/queries'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { useSchemaTranslations } from '@/eshop/hooks/useSchemaTranslations'
import { useRouter } from 'next/navigation'
import { toast } from 'react-toastify'

interface Props extends FormProps {}
type Response = {
    state: 'idle' | 'ok' | 'error'
    msg: string | null
}

export default function ForgottenPasswordForm({ onDone }: Props) {
    const { t } = useTranslation()
    const [response, setResponse] = useState<Response>({
        state: 'idle',
        msg: null,
    })
    const schemaT = useSchemaTranslations()
    const { push } = useRouter()

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<ForgottenPwdFormData>({
        resolver: zodResolver(getForgottenPwdSchema(schemaT)),
        mode: 'onChange',
    })

    const { mutate, isPending } = useMutation({
        mutationFn: (data: ForgottenPwdFormData) =>
            changePasswordMessage()({ username: data.email }).toPromise(),
        onMutate: () => {
            setResponse({
                state: 'idle',
                msg: null,
            })
        },
        onSuccess: (body) => {
            body.match({
                Ok: (r) => {
                    reset()
                    setResponse({
                        state: 'ok',
                        msg: t('eshop.forgottenPwd.messageSendSuccess'),
                    })
                    toast.success(t('eshop.forgottenPwd.messageSendSuccess'))
                    setTimeout(() => {
                        push('/eshop')
                    }, 1000)
                    onDone()
                },
                Error: (e) => {
                    let errorMsg: string | undefined = (e?.error as any)
                        ?.response?.errors?.[0]?.message
                    if (errorMsg) {
                        errorMsg = decodeURIComponent(errorMsg)
                        errorMsg = errorMsg
                            ?.replace(/^"/, '')
                            ?.replace(/"$/, '')
                            ?.replaceAll('\\"', '"')
                    }
                    setResponse({
                        state: 'error',
                        msg:
                            t('eshop.forgottenPwd.messageSendFail') +
                            (errorMsg ? ` - ${errorMsg}` : ''),
                    })
                },
            })
        },
        onError() {
            setResponse({
                state: 'error',
                msg: t('eshop.forgottenPwd.messageSendFail'),
            })
        },
    })

    const onSubmit = useCallback(
        (data: ForgottenPwdFormData) => {
            mutate(data)
        },
        [mutate]
    )

    return (
        <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
            <EshopFormHeading className="my-8">
                {t('eshop.forgottenPwd.messageFormTitle')}
            </EshopFormHeading>
            <TextInput
                placeholder="E-mail"
                className={eshopInputClasses}
                {...register('email')}
                error={errors?.email?.message}
            />
            {response.state !== 'idle' && (
                <div
                    className={clsx(
                        'font-bold mt-4 text-center',
                        response.state === 'error'
                            ? 'text-red-700'
                            : 'text-green-700'
                    )}
                >
                    {response.state === 'error'
                        ? `Error: ${response.msg}`
                        : response.state === 'ok'
                        ? response.msg
                        : ''}
                </div>
            )}
            <div className="flex justify-center py-6">
                <EshopFormButton
                    disabled={isPending || response.state === 'ok'}
                >
                    {isPending ? (
                        <div className="flex justify-center px-2">
                            <span className="loading loading-spinner"></span>
                        </div>
                    ) : (
                        t('eshop.forgottenPwd.messageFormBtn')
                    )}
                </EshopFormButton>
            </div>
        </form>
    )
}
