import { forwardRef } from 'react'
import clsx from 'clsx'
import InputError from './InputError'
import { getTextInputStyles } from './styles'
import { TextInputProps } from './types'

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
    function TextInput(
        {
            name,
            type = 'text',
            value,
            placeholder,
            label,
            required,
            onChange,
            error,
            className,
            testId,
            ...rest
        },
        ref
    ) {
        let labelText
        if (label) labelText = required ? `${label} *` : label

        return (
            <>
                {!!label && <label htmlFor={name}>{labelText}</label>}

                <input
                    {...rest}
                    type={type}
                    name={name}
                    value={value}
                    placeholder={placeholder}
                    required={required}
                    onChange={onChange}
                    className={clsx(
                        getTextInputStyles(rest.disabled),
                        className,
                        !!error && 'mb-0'
                    )}
                    ref={ref}
                    data-testid={testId}
                />
                <InputError error={error} />
            </>
        )
    }
)
