'use client'
import { TextInput } from '@/components/FormComponents/textInput'
import { eshopInputClasses } from '@/eshop/constants'
import {
    LoginFormData,
    getLoginSchema,
    loginResponseSchema,
} from '@/eshop/utils/schema'
import { useCallback, useContext, useRef, useState } from 'react'
import EshopFormHeading from '../FormHeading'
import { EshopFormButton } from '../FormButton'
import { EshopContext } from '../EshopProvider'
import { useMutation } from '@tanstack/react-query'
import { addTokenToCookie } from '@/eshop/utils/client'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useRouter } from 'next/navigation'
import { FormProps } from './shared'
import { useTranslation } from 'react-i18next'
import { useSchemaTranslations } from '@/eshop/hooks/useSchemaTranslations'

interface Props extends FormProps {}

export default function LoginForm({ onDone, onChangeForm }: Props) {
    const formEl = useRef<HTMLFormElement>(null)
    const { setUser } = useContext(EshopContext)
    const [error, setError] = useState<string | null>(null)
    const { t } = useTranslation()
    const schemaT = useSchemaTranslations()

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isSubmitting },
    } = useForm<LoginFormData>({
        resolver: zodResolver(getLoginSchema(schemaT)),
        mode: 'onChange',
    })

    const { push } = useRouter()

    const { mutate, isPending } = useMutation({
        mutationFn: (data: LoginFormData) =>
            fetch('/api/eshop/login', {
                method: 'POST',
                body: JSON.stringify(data),
            })
                .then((res) => res.json())
                .then((res) => loginResponseSchema.safeParse(res)),
        onMutate: () => {
            setError(null)
        },
        onSuccess: (body) => {
            if (!body.success) {
                setError(t('eshop.login.unknownError'))
            } else if (body.success && body.data.status === 'failed') {
                setError(body.data.error ? t(body.data.error) : null)
                return
            } else if (body.success && body.data.status === 'success') {
                setError(null)
                setUser(body.data.data.user)
                addTokenToCookie(
                    body.data.data.token,
                    body.data.data.user.tokenExpiring
                )
                toast.success(t('eshop.login.successToast'))
                onDone()
                reset()
                push('/eshop/')
                setTimeout(() => {
                    window?.location?.reload()
                }, 250)
            }
        },
    })

    const onSubmit = useCallback(
        (data: LoginFormData) => {
            mutate(data)
        },
        [mutate]
    )

    return (
        <form
            className="flex flex-col"
            onSubmit={handleSubmit(onSubmit)}
            ref={formEl}
        >
            <EshopFormHeading className="my-8">
                {t('eshop.login.title')}
            </EshopFormHeading>
            <TextInput
                placeholder={t('eshop.login.email')}
                className={eshopInputClasses}
                {...register('email')}
                error={errors?.email?.message}
                testId="userEmailInput"
            />
            <TextInput
                type="password"
                placeholder={t('eshop.login.password')}
                {...register('password')}
                error={errors?.password?.message}
                className={eshopInputClasses}
            />
            <span
                onClick={onChangeForm}
                className="curspor-pointer text-center link text-primary mt-4"
            >
                {t('eshop.login.forgottenPassword')}
            </span>
            <div className="flex justify-center pt-6">
                <EshopFormButton disabled={isPending}>
                    {isPending ? (
                        <div className="flex justify-center px-2">
                            <span className="loading loading-spinner"></span>
                        </div>
                    ) : (
                        t('eshop.login.loginBtn')
                    )}
                </EshopFormButton>
            </div>
            <div className="font-bold mt-4 h-6 text-red-700 text-center">
                {error ? `${t('eshop.login.error')}: ${error}` : ''}
            </div>
        </form>
    )
}
