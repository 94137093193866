import LanguageSwitcher from '@/components/LanguageSwitcher'
import Modal, { Props as ModalProps } from '../Modal'
import { NavbarUser } from './User'
import CurrencySwitcher from '../CurrencySwitcher'
import { useContext, useEffect, useState } from 'react'
import { EshopContext } from '../EshopProvider'
import clsx from 'clsx'
import Link from 'next/link'
import { ChevronLeftIcon } from '@heroicons/react/20/solid'
import NavbarSearch from './Search'
import { useTranslation } from 'react-i18next'
import { Language } from '@/utils/language'

interface Props extends ModalProps {
    nativeLanguage?: Language
}

export default function NavbarMobileMenu(props: Props) {
    const { showMobileMenu } = useContext(EshopContext)
    const [show, setShow] = useState(false)
    const { t } = useTranslation()

    useEffect(() => {
        if (!showMobileMenu) {
            return setShow(false)
        }

        setTimeout(() => {
            setShow(true)
        }, 300)
    }, [showMobileMenu])

    return (
        <Modal
            {...props}
            className={clsx(
                'transition absolute right-0 top-0 duration-500 min-h-screen max-w-[320px] tex-md'
            )}
        >
            <div className="flex flex-col items-end">
                <NavbarSearch />
                <Link href="/about" className="my-4 mr-4">
                    <div className="flex items-center">
                        <ChevronLeftIcon className="w-11 h-11 rounded-full border p-2 mr-2 hover:bg-gray-200" />
                        About Lach:ner
                    </div>
                </Link>
                <Link href="/eshop" className="my-4 mr-4">
                    {t('eshop.navbar.products')}
                </Link>
                <Link href="/eshop/user/orders" className="my-4 mr-4">
                    {t('eshop.navbar.orders')}
                </Link>
                <Link href="/eshop/documents" className="my-4 mr-4">
                    {t('eshop.navbar.documents')}
                </Link>
                <div className="flex mb-6">
                    <LanguageSwitcher nativeLanguage={props.nativeLanguage} />
                    <CurrencySwitcher />
                </div>
                <NavbarUser onClick={props.onHide} className="mr-4 mb-6" />
            </div>
        </Modal>
    )
}
