import clsx from 'clsx'

interface Props {
    error?: string
    className?: string
}

export default function InputError({ error, className }: Props) {
    return error ? (
        <div className={clsx('text-sm text-red-700 mt-1', className)}>
            {error}
        </div>
    ) : null
}
