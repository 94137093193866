'use client'
import { Dropdown, DropdownProps } from '@/components/Dropdown'
import { MouseEventHandler, useContext, useEffect, useMemo } from 'react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { CURRENCY_STORAGE_KEY, DEFAULT_CURRENCY } from '@/constants'
import { Currency, currencySchema } from '@/utils/currency'
import { EshopContext } from '../EshopProvider'
import { Option } from '@swan-io/boxed'
import { getCookieOnClient } from '@/utils/cookie'

export default function CurrencySwitcher() {
    const { currency, setCurrency, country } = useContext(EshopContext)

    // set cookie on client to avoid default setting on CMS components
    useEffect(() => {
        Option.fromNullable(getCookieOnClient(CURRENCY_STORAGE_KEY))
            .flatMap((v) => {
                const result = currencySchema.safeParse(v)
                if (result.success) {
                    return Option.Some(result.data)
                }
                return Option.None()
            })
            .match({
                Some: (v) => {
                    setCurrency(v)
                },
                None: () => {},
            })
    }, [setCurrency])

    const currencyData: DropdownProps['items'] = useMemo(() => {
        const createEventHandler =
            (currency: Currency): MouseEventHandler =>
            (e) => {
                e.preventDefault()
                e.stopPropagation()
                setCurrency(currency)

                fetch('/api/eshop/currency-cookie', {
                    method: 'POST',
                    body: JSON.stringify({ currency }),
                }).then(() => {
                    window?.location.reload()
                })
            }
        const curr = [
            {
                label: 'EUR',
                onClick: createEventHandler('EUR'),
            },
        ]

        switch (country) {
            case 'cs':
                curr.push({
                    label: 'CZK',
                    onClick: createEventHandler('CZK'),
                })
                break

            case 'hu':
                curr.push({
                    label: 'HUF',
                    onClick: createEventHandler('HUF'),
                })
                break

            default:
                break
        }

        return curr
    }, [setCurrency, country])

    return (
        <Dropdown
            className="mr-4 pl-4 border-l border-gray-light2"
            labelClassName="flex flex-nowrap p-2"
            label={
                <>
                    {currency.toUpperCase()}
                    <ChevronDownIcon className="w-[16px]" />
                </>
            }
            items={currencyData}
        />
    )
}
