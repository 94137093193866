'use client'
import i18next from 'i18next'
import {
    PropsWithChildren,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react'
import { createContext } from 'react'
import { initReactI18next } from 'react-i18next'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { User } from '../utils/schema'
import { addTokenToCookie, removeTokenFromCookie } from '../utils/client'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Login from './Login'
import NavbarMobileMenu from './Navbar/MobileMenu'
import {
    BasketProduct,
    BasketProvider,
    basketProductsSchema,
} from './BasketProvider'
import { parseSchemaToResultCurry, tryCatch } from '@/utils/request'
import { BASKET_STORAGE_KEY, DEFAULT_LANGUAGE } from '@/constants'
import { Language } from '@/utils/language'
import { ESHOP_TOKEN_COOKIE_NAME } from '../constants'
import { getCookieOnClient } from '@/utils/cookie'
import { Option } from '@swan-io/boxed'
import { getMinimalUserInfo } from '../queries'
import { Currency } from '@/utils/currency'
import { languageToLanguageId } from '@/eshop/hooks/useK2Setup'

const createInstance = (t: Record<string, string>) =>
    i18next.use(initReactI18next).init({
        lng: 'en', // if you're using a language detector, do not define the lng option
        // TODO: debug only on test
        debug: false,
        resources: {
            en: {
                translation: t,
            },
        },
    })

export interface EshopContextCategory {
    id: string | null
    name: string
    languageId: number
    productCount?: number
}

export type LoginModal = 'login' | 'password' | null

export const EshopContext = createContext<{
    lang: Language
    setLang: (l: Language) => void
    currency: Currency
    setCurrency: (c: Currency) => void
    t: any
    user: User | null
    setUser: (user: User | null) => void
    showLoginModal: LoginModal
    setShowLoginModal: (v: LoginModal) => void
    showMobileMenu: boolean
    setShowMobileMenu: (v: boolean) => void
    search: string
    setSearch: (s: string) => void
    categories: EshopContextCategory[]
    setCategories: (cats: EshopContextCategory[]) => void
    country: Language
}>({
    lang: 'cs',
    setLang: () => {},
    currency: 'CZK',
    setCurrency: () => {},
    t: undefined,
    user: null,
    setUser: () => {},
    showLoginModal: null,
    setShowLoginModal: () => {},
    showMobileMenu: false,
    setShowMobileMenu: () => {},
    search: '',
    setSearch: () => {},
    categories: [],
    setCategories: () => {},
    country: 'cs',
})

export const defaultCategory: EshopContextCategory = {
    id: null,
    name: '---',
    languageId: languageToLanguageId[DEFAULT_LANGUAGE],
}

export function EshopContextProvider({
    children,
    defaultLang,
    defaultCurrency,
    translation,
    token,
    defaultUser,
    nativeLanguage,
}: PropsWithChildren<{
    defaultLang: Language
    defaultCurrency: Currency
    translation: Record<string, string>
    token?: string
    defaultUser?: User | null
    nativeLanguage: Language
}>) {
    const [i18instance] = useState(() => createInstance(translation))
    const [queryClient] = useState(
        () =>
            new QueryClient({
                defaultOptions: {
                    queries: {
                        refetchOnWindowFocus: false,
                    },
                },
            })
    )
    const [user, setUser] = useState<User | null>(defaultUser || null)
    const [showLoginModal, setShowLoginModal] = useState<LoginModal>(null)
    const [showMobileMenu, setShowMobileMenu] = useState(false)
    const [search, setSearch] = useState('')
    const [lang, setLang] = useState(defaultLang)
    const [currency, setCurrency] = useState(defaultCurrency)
    const [categories, setCategories] = useState<EshopContextCategory[]>([
        { ...defaultCategory },
        { ...defaultCategory },
        { ...defaultCategory },
        { ...defaultCategory },
    ])
    const checkedToken = useRef<string | null>(null)

    const handleLoginModalClose = useCallback(() => {
        setShowLoginModal(null)
    }, [])

    const handleMobileMenuClose = useCallback(() => {
        setShowMobileMenu(false)
    }, [])

    const tokenAdded = useRef(false)
    const basketAdded = useRef(false)

    // sets token in client, because we cannot do that on server
    if (!tokenAdded.current) {
        addTokenToCookie(token)
        tokenAdded.current = true
    }

    // sets token in client, because we cannot do that on server
    let initialProducts: BasketProduct[] = []
    if (!basketAdded.current) {
        //load basket form localstorage
        initialProducts = tryCatch(() =>
            JSON.parse(
                window?.localStorage?.getItem(BASKET_STORAGE_KEY) || '[]'
            )
        )
            .flatMap(parseSchemaToResultCurry(basketProductsSchema))
            .match({
                Ok: (data) => data,
                Error: () => [],
            })
        basketAdded.current = true
    }

    useEffect(() => {
        const ti = setInterval(() => {
            Option.fromNullable(
                getCookieOnClient(ESHOP_TOKEN_COOKIE_NAME)
            ).match({
                Some: (currentToken) => {
                    if (
                        currentToken !== token &&
                        checkedToken.current !== currentToken
                    ) {
                        fetch('/api/eshop/check-token', {
                            method: 'POST',
                        }).then((res) => {
                            if (res.status >= 500) {
                                setUser(null)
                                removeTokenFromCookie()
                                window?.location?.reload()
                                return
                            } else {
                                checkedToken.current = currentToken
                            }
                        })
                    }
                },
                None: () => {
                    setUser(null)
                    window?.location?.reload()
                },
            })
        }, 5_000)

        return () => {
            clearInterval(ti)
        }
    }, [token])

    // get additional user info
    useEffect(() => {
        if (!user || user.isAnonymous || !!user.info) {
            return
        }

        async function run() {
            const data = await getMinimalUserInfo().toPromise()
            data?.match({
                Ok: (v) => {
                    setUser((u) =>
                        u
                            ? {
                                  ...u,
                                  info:
                                      v.currentUser.name &&
                                      v.currentUser.surname
                                          ? {
                                                name: v.currentUser.name!,
                                                surname: v.currentUser.surname!,
                                            }
                                          : undefined,
                              }
                            : null
                    )
                },
                Error: () => {},
            })
        }

        run()
    }, [user])

    return (
        <EshopContext.Provider
            value={{
                lang,
                setLang,
                currency,
                setCurrency,
                t: i18instance,
                user,
                setUser,
                showLoginModal,
                setShowLoginModal,
                showMobileMenu,
                setShowMobileMenu,
                search,
                setSearch,
                categories,
                setCategories,
                country: nativeLanguage,
            }}
        >
            <BasketProvider initialProducts={[]} user={user}>
                <QueryClientProvider client={queryClient}>
                    {children}
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                    />
                    <Login
                        show={showLoginModal !== null}
                        onHide={handleLoginModalClose}
                    />
                    <NavbarMobileMenu
                        nativeLanguage={nativeLanguage}
                        show={showMobileMenu}
                        onHide={handleMobileMenuClose}
                    />
                </QueryClientProvider>
            </BasketProvider>
        </EshopContext.Provider>
    )
}
