import { Future, Result } from '@swan-io/boxed'
import { ZodError, ZodSchema } from 'zod'

export class RequestError<E = unknown> {
    _tag = 'RequestError' as const
    constructor(
        public message: string,
        public error: E,
        public name?: string
    ) {}
}

export function requestToResult<T>(
    promise: () => Promise<T>,
    name?: string
): Promise<Result<T, RequestError>> {
    return promise()
        .then((res) => Result.Ok(res))
        .catch((e) => {
            return Result.Error(
                new RequestError('error during request', e, name)
            )
        })
}

export function requestToFuture<T, E = unknown>(
    request: () => Promise<T>,
    errorName?: string
): Future<Result<T, RequestError<E>>> {
    return Future.fromPromise(request()).mapErrorToResult((e) =>
        Result.Error(new RequestError<E>('REQUEST error', e as E, errorName))
    )
}

class SchemaError<T> {
    _tag = 'SchemaError' as const
    constructor(
        public message: string,
        public zodError: ZodError<T>
    ) {}
}

export function parseSchemaToResult<T>(
    schema: ZodSchema<T>,
    data: any
): Result<T, SchemaError<T>> {
    const result = schema.safeParse(data)

    if (result.success) {
        return Result.Ok(result.data)
    }
    return Result.Error(new SchemaError('parsing error', result.error))
}

export function parseSchemaToResultCurry<T>(schema: ZodSchema<T>) {
    return (data: any) => parseSchemaToResult(schema, data)
}

class TryCatchError<E> {
    _tag = 'TryCatchError' as const

    constructor(
        public message: string,
        public error: E
    ) {}
}

export function tryCatch<A, E = unknown>(
    fn: () => A,
    onError?: (error: unknown) => string
): Result<A, TryCatchError<E>> {
    try {
        const result = fn()
        return Result.Ok(result)
    } catch (e) {
        return Result.Error(
            new TryCatchError<E>(
                onError?.(e) || 'Error in tryCatch function',
                e as E
            )
        )
    }
}

export function parseJsonToResult<A>(fn: () => A) {
    return tryCatch<A, SyntaxError>(fn, (e) => 'Invalid JSON to parse')
}

export function tryCatchPromise<A, E = unknown>(
    lazyPromise: () => Promise<A>,
    onError?: (error: unknown) => string
): Future<Result<A, TryCatchError<E>>> {
    return Future.fromPromise(lazyPromise())
        .mapOkToResult((data) => Result.Ok(data))
        .mapErrorToResult((e) =>
            Result.Error(
                new TryCatchError<E>(
                    onError?.(e) || 'trycatchpromise error',
                    e as E
                )
            )
        )
}
