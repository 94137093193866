'use client'
import clsx from 'clsx'
import { PropsWithChildren, useCallback, useEffect, useRef } from 'react'

export interface Props {
    show: boolean
    onHide?: () => void
    className?: string
}

export default function Modal({
    show,
    children,
    onHide,
    className,
}: PropsWithChildren<Props>) {
    const el = useRef<HTMLDialogElement>(null)

    const handleModalClick = useCallback(
        (e: React.MouseEvent<HTMLDialogElement>) => {
            if (e.target === el.current) {
                onHide?.()
            }
        },
        [onHide]
    )

    useEffect(() => {
        const element = el.current

        if (!element) {
            return
        }

        const isOpen = element.open
        if (show && !isOpen) {
            element.showModal()
        }

        if (!show && isOpen) {
            element.close()
        }

        const closeHandler = () => onHide?.()
        element.addEventListener('close', closeHandler)

        return () => {
            element.removeEventListener('close', closeHandler)
        }
    }, [show, onHide])

    return (
        <dialog
            ref={el}
            id="lachmodal"
            className="modal"
            onMouseDown={handleModalClick}
        >
            <div
                className={clsx(
                    'modal-box rounded-none pt-10 bg-gray-light',
                    className
                )}
            >
                <form method="dialog">
                    <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
                        ✕
                    </button>
                </form>
                {children}
            </div>
        </dialog>
    )
}
