import { SelectProps } from './types'
import ReactSelect, { ActionMeta } from 'react-select'
import InputError from './InputError'
import clsx from 'clsx'
import { forwardRef } from 'react'

export const Select = forwardRef<any, SelectProps>(function Select(
    {
        labelInside,
        options,
        required,
        label,
        error,
        onChange,
        value,
        wrapperClassName,
        ...restProps
    },
    ref
) {
    const labelText = required ? `${label} *` : label
    const customStyles = {
        dropdownIndicator: (base: any) => ({
            ...base,
            color: '#13253b', // Custom colour
        }),
    }

    return (
        <div className={clsx('relative', wrapperClassName)}>
            {!!label && (
                <label
                    className={
                        labelInside
                            ? 'absolute z-10 top-[16px] left-[10px] text-[14px] text-blue-middle '
                            : undefined
                    }
                    htmlFor={restProps.name}
                >
                    {labelText}
                </label>
            )}
            <ReactSelect
                classNamePrefix="rselect"
                options={options}
                styles={customStyles}
                classNames={{
                    indicatorSeparator: () => 'hidden',
                    valueContainer: () =>
                        labelInside ? 'absolute top-[10px]' : '',
                    control: (state) =>
                        clsx(
                            'border-1 border-gray-light2 !rounded-lg py-4',
                            state.isFocused &&
                                '!shadow-none !border-black !bg-[#f5f5f5]',
                            !!error ? 'mt-2' : 'my-2'
                        ),
                    option: (state) =>
                        `!text-blue-dark ${
                            state.isFocused ? '!bg-[#f5f5f5]' : ''
                        } ${state.isSelected ? '!bg-[#f5f5f5]' : ''}`,
                    singleValue: () => '!text-blue-dark',
                }}
                onChange={onChange}
                value={options.find((option) => option.value === value)}
                {...restProps}
                ref={ref}
            />
            <InputError error={error} className="mb-2" />
        </div>
    )
})
