'use client'
import { useCallback, useContext, useRef, useState } from 'react'
import EshopLink from '../Link'
import Modal, { Props as ModalProps } from '../Modal'
import LoginForm from './LoginForm'
import ForgottenPasswordForm from './ForgottenPwdForm'
import { useTranslation } from 'react-i18next'
import { EshopContext, LoginModal } from '../EshopProvider'
import { useUser } from '@/eshop/hooks/useUser'

interface Props extends ModalProps {}

export default function Login({ show, onHide }: Props) {
    const { showLoginModal, setShowLoginModal } = useContext(EshopContext)
    const { t } = useTranslation()
    const user = useUser()

    const handleChangeForm = useCallback(
        (f: LoginModal) => () => {
            setShowLoginModal(f)
        },
        [setShowLoginModal]
    )

    const handleModalHide = useCallback(() => {
        setShowLoginModal(null)
        onHide?.()
    }, [onHide, setShowLoginModal])

    return (
        <Modal show={show} onHide={handleModalHide}>
            <div>
                {showLoginModal === 'login' && (
                    <LoginForm
                        onChangeForm={handleChangeForm('password')}
                        onDone={handleModalHide}
                    />
                )}
                {showLoginModal === 'password' && (
                    <ForgottenPasswordForm
                        onDone={handleModalHide}
                        onChangeForm={handleChangeForm('login')}
                    />
                )}
                <div className="mt-20 text-center">
                    {(!user || user?.isAnonymous) && (
                        <>
                            {t('eshop.login.noAccount')}
                            <EshopLink
                                color="secondary"
                                href="/eshop/register"
                                className="ml-1"
                                onClick={handleModalHide}
                            >
                                {t('eshop.login.noAccountLink')}
                            </EshopLink>
                        </>
                    )}
                </div>
            </div>
        </Modal>
    )
}
