import { ReadonlyRequestCookies } from 'next/dist/server/web/spec-extension/adapters/request-cookies'
import { parseSchemaToResult } from './request'
import { z } from 'zod'
import { CURRENCY_STORAGE_KEY, DEFAULT_CURRENCY } from '@/constants'

export const currencySchema = z.enum(['CZK', 'EUR', 'HUF'])
export type Currency = z.infer<typeof currencySchema>

export function getCurrencyFromCookie(cookieStore: ReadonlyRequestCookies) {
    return parseSchemaToResult(
        currencySchema,
        cookieStore.get(CURRENCY_STORAGE_KEY)?.value
    ).match({
        Ok: (v) => v,
        Error: () => DEFAULT_CURRENCY as Currency,
    })
}
