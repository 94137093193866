'use client'

import { useContext, useEffect, useState } from 'react'
import TextInput from '../TextInput'
import { useRouter } from 'next/navigation'
import { EshopContext } from '../EshopProvider'
import { useSearchParams } from 'next/navigation'

export default function NavbarSearch() {
    const { setSearch } = useContext(EshopContext)
    const { push } = useRouter()
    const searchParams = useSearchParams()
    const searchQuery = searchParams.get('search') || ''
    const [inputValue, setInputValue] = useState(searchQuery)
    const [showSearch, setShowSearch] = useState(!!searchQuery)

    useEffect(() => {
        setInputValue(searchQuery)
    }, [searchQuery])

    return (
        <div
            className="flex items-center mx-4 gap-2 h-11"
            data-testid="headerSearchButton"
        >
            {showSearch && (
                <TextInput
                    size="sm"
                    className="my-0 bg-white z-50 py-2 shadow-sm"
                    name="search"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={(e) => {
                        const target = e.target as HTMLInputElement
                        if (e.code === 'Enter') {
                            setSearch(target.value)
                            push(
                                `/eshop?search=${encodeURIComponent(
                                    target.value
                                )}`
                            )
                        }
                    }}
                />
            )}
            <svg
                className="cursor-pointer shrink-0"
                onClick={() => setShowSearch((state) => !state)}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle
                    cx="11.7664"
                    cy="11.7659"
                    r="8.98856"
                    stroke="#5B6578"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M18.0181 18.4844L21.5421 21.9992"
                    stroke="#5B6578"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    )
}
