export const PAGE_SIZE = 20

export const ESHOP_TOKEN_COOKIE_NAME = 'lchr_eshop_token'

export const ESHOP_COOKIE_CONSENT_NAME = 'lchr_eshop_cookies'

export const eshopInputClasses = 'border-1 rounded-lg py-4'

export const manufacturers = ['Lach-Ner'] as const

export const defaultManufacturer = 'Lach-Ner' as const

export const layoutPortalId = 'eshoplayoutportal'
