'use client'
import { useContext, useMemo } from 'react'
import { EshopContext } from '../components/EshopProvider'
import { User } from '../utils/schema'

interface UseUser extends User {
    isAdmin: boolean
}

export function useUser() {
    const { user } = useContext(EshopContext)

    return useMemo((): UseUser | null => {
        if (!user || user.isAnonymous) {
            return null
        }
        return {
            ...user,
            isAdmin: user.roles.includes('ROLE_COMPANY_ADMIN'),
        }
    }, [user])
}
