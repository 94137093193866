import { SchemaTranslations } from '@/eshop/hooks/useSchemaTranslations'
import { z } from 'zod'

export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/

export const passwordValidation = z
    .string()
    .min(8, 'Password must be at least 8 characters long')
    .regex(/[A-Z]/, 'Password must have at least one uppercase letter')
    .regex(
        /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/,
        'Password must have at least one special character'
    )
    .regex(/\d/, 'Password must have at least one number')

export function getPasswordValidationT(t: SchemaTranslations) {
    return z
        .string()
        .min(8, t.pwdChars)
        .regex(/[A-Z]/, t.pwdUpper)
        .regex(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/, t.pwdSpecial)
        .regex(/\d/, t.pwdNumber)
}

export const phoneRegex = /^[0-9\s]{9,}$/

export const phoneValidation = z
    .string()
    .min(9)
    .regex(phoneRegex, 'Bad phone format')
