import { Option, Result } from '@swan-io/boxed'
import { ESHOP_TOKEN_COOKIE_NAME } from '../constants'
import { searchProducts } from '../queries'
import { Product } from '../gql/graphql'

export function addTokenToCookie(token?: string | null, expires?: number) {
    if (
        typeof window !== 'undefined' &&
        typeof document !== 'undefined' &&
        token &&
        !document.cookie?.includes(token)
    ) {
        // document.cookie = `${ESHOP_TOKEN_COOKIE_NAME}=${token}; SameSite=None; Secure";`
        document.cookie = `${ESHOP_TOKEN_COOKIE_NAME}=${token}; path=/;${
            expires ? `expires=${new Date(expires).toUTCString()};` : ''
        }`
    }
}

export function removeTokenFromCookie() {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
        document.cookie = `${ESHOP_TOKEN_COOKIE_NAME}=""; path=/;`
    }
}

export function downloadBase64File(name: string, base64: string) {
    const link = document.createElement('a')
    const file = base64ToFile(base64!, `${name}.pdf`)
    const objectUrl = URL.createObjectURL(file)
    const filename = `${name}.pdf`

    link.href = objectUrl
    link.download = filename
    document.body.appendChild(link)
    link.click()
    setTimeout(() => {
        document.body.removeChild(link)
    }, 2_000)
}

function base64ToFile(
    base64Data: string,
    fileName: string,
    contentType = 'application/pdf'
) {
    const byteCharacters = atob(base64Data)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)

    const file = new File([byteArray], fileName, { type: contentType })
    return file
}

export class HydrateError {
    _tag = 'HydrateError' as const

    constructor(public message: string) {}
}

export function hydrateResult<Ok, Err>(
    res: Result<Ok, Err> | undefined
): Result<Ok, Err | HydrateError> | undefined {
    if (!res) {
        return undefined
    }
    if (!!res && 'tag' in res) {
        if (res.tag === 'Ok' && 'value' in res) {
            return Result.Ok(res.value)
        }
        if (res.tag === 'Error' && 'error' in res) {
            return Result.Error(res.error)
        }
    }
    return Result.Error(new HydrateError('wrong object'))
}

export async function getProductFromProductGroupName(
    groupName: string,
    articleId: number,
    languageId: number
): Promise<Option<Product>> {
    const product = await searchProducts()({
        term: {
            eq: groupName,
        },
        languageId: {
            eq: languageId,
        },
    }).toPromise()

    console.log({ product })

    return product.match({
        Ok: (r) =>
            Option.fromUndefined(
                r.searchProducts.edges?.find(
                    (e) => e.node.articleId === articleId
                )?.node
            ),
        Error: () => Option.None(),
    })
}
