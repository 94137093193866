import clsx from 'clsx'

export const basicInputStyles =
    'border border-form-border bg-white focus:bg-[#f5f5f5] focus:border-black focus:outline-0 py-2 px-3 my-2'

export const textInputStyles = `${basicInputStyles} w-full`

export function getTextInputStyles(disabled?: boolean) {
    if (!disabled) {
        return textInputStyles
    }
    return clsx(
        basicInputStyles.replace('bg-white', ''),
        'bg-gray-light text-gray-600'
    )
}
