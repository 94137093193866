'use client'
import {
    PropsWithChildren,
    createContext,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react'
import { z } from 'zod'
import { Product, ProductPrice } from '../gql/graphql'
import { Option, Result } from '@swan-io/boxed'
import { BASKET_INVOICE_STORAGE_KEY, BASKET_STORAGE_KEY } from '@/constants'
import { parseSchemaToResultCurry, tryCatch } from '@/utils/request'
import { makeProductDetailUrl } from '../utils/url'
import { User } from '../utils/schema'

export const basketProductSchema = z.object({
    id: z.string().nonempty(),
    name: z.string().nonempty(),
    count: z.number(),
    unit: z.string(),
    price: z.union([z.number(), z.string()]),
    priceVAT: z.union([z.number(), z.string()]),
    catNo: z.string(),
    currency: z.string(),
    manufacturer: z.string(),
    manufacturerId: z.string(),
    available: z.string().optional(),
    link: z.string(),
    goodsId: z.number(),
    userId: z.number().nullable(),
})

export type BasketProduct = z.infer<typeof basketProductSchema>

export const basketInvoiceSchema = z.object({
    deliveryAddress: z.number().positive(),
    contactPerson: z.number().positive(),
    delivery: z.number().positive(),
    payment: z.number().positive(),
    userId: z.number().nullable(),
})

export type BasketInvoice = z.infer<typeof basketInvoiceSchema>

const defaultInvoice: BasketInvoice = {
    contactPerson: 0,
    delivery: 0,
    deliveryAddress: 0,
    payment: 0,
    userId: 0,
}

function getDefaultInvoiceDataForUser(userId: number | null) {
    return {
        ...defaultInvoice,
        userId,
    }
}

export function getBasketProductFromProduct(
    p: Product,
    priceId: ProductPrice['id'],
    count: number,
    userId: User['id'],
    priceNetto: string,
    priceBrutto: string
): Option<BasketProduct> {
    const pp = p.productPrices.find((pp) => pp.id === priceId)
    const pi = p.productLangItems.find((pl) => pl.articleId === pp?.articleId)
    const pg = p.productGroup
    if (!pp || !pi || !pg) {
        return Option.None()
    }

    return Option.Some<BasketProduct>({
        id: priceId,
        name: pg.sortingNameLang,
        count,
        unit: pi.unit || '',
        price: priceNetto,
        priceVAT: priceBrutto,
        catNo: p.abbr || '',
        currency: pp.currency,
        manufacturer: pi.brandName || '',
        link: makeProductDetailUrl(pg.sortingNameLang, p.id),
        goodsId: p.articleId,
        userId,
        manufacturerId: p.brandRID || '',
    })
}

export const basketProductsSchema = z.array(basketProductSchema)

export interface BasketContextProps {
    invoice: BasketInvoice
    products: BasketProduct[]
    changeInvoice: <TKey extends keyof BasketInvoice>(
        key: TKey,
        val: BasketInvoice[TKey]
    ) => void
    addProduct: (p: BasketProduct) => void
    removeProduct: (p: BasketProduct) => void
    changeCount: (catNo: string, count: number) => void
    changeProductPrice: (
        productId: BasketProduct['id'],
        priceBrutto: string,
        priceNetto: string
    ) => void
    isInitiated: boolean
    resetInvoice: () => void
    resetProducts: () => void
    isLoading: boolean
    setIsLoading: (l: boolean) => void
    note?: string
    setNote: (s: string | undefined) => void
    internalOrderNumber?: string
    setInternalOrderNumber: (s: string | undefined) => void
}

export const BasketContext = createContext<BasketContextProps>({
    invoice: defaultInvoice,
    changeInvoice: () => {},
    products: [],
    addProduct: () => {},
    removeProduct: () => {},
    changeCount: () => {},
    changeProductPrice: () => {},
    isInitiated: false,
    resetInvoice: () => {},
    resetProducts: () => {},
    isLoading: false,
    setIsLoading: () => {},
    setInternalOrderNumber: () => {},
    setNote: () => {},
})

export function BasketProvider({
    initialProducts,
    children,
    user,
}: PropsWithChildren<{ initialProducts: BasketProduct[]; user: User | null }>) {
    const [products, setProducts] = useState(initialProducts)
    const [isLoading, setIsLoading] = useState(false)
    const userId = user?.id || null
    const [invoice, setInvoice] = useState<BasketInvoice>(
        getDefaultInvoiceDataForUser(userId)
    )
    const [isInitiated, setIsInitiated] = useState(false)
    const [note, setNote] = useState<string | undefined>(undefined)
    const [internalOrderNumber, setInternalOrderNumber] = useState<
        string | undefined
    >(undefined)

    const handleResetInvoice = useCallback(() => {
        setInvoice(getDefaultInvoiceDataForUser(userId))
    }, [userId])

    const handleResetProducts = useCallback(() => {
        setProducts([])
    }, [])

    const handleChangeInvoiceData = useCallback(
        <TKey extends keyof BasketInvoice>(
            key: TKey,
            val: BasketInvoice[TKey]
        ) => {
            setInvoice((state) => ({
                ...state,
                [key]: val,
            }))
        },
        []
    )

    const handleChangeCount = useCallback((catNo: string, count: number) => {
        setProducts((state) =>
            state.map((p) =>
                p.catNo === catNo
                    ? {
                          ...p,
                          count: p.count + count,
                      }
                    : p
            )
        )
    }, [])

    const handleChangeProductPrice: BasketContextProps['changeProductPrice'] =
        useCallback(
            (productId, priceBrutto, priceNetto) => {
                setProducts((state) =>
                    state.map((p) => {
                        if (p.id !== productId) {
                            return p
                        }
                        return {
                            ...p,
                            price: priceNetto,
                            priceVAT: priceBrutto,
                        }
                    })
                )
            },
            [products, setProducts]
        )

    const handleAddProduct = useCallback((p: BasketProduct) => {
        setProducts((state) =>
            state.find((pr) => pr.id === p.id)
                ? state.map((pr) =>
                      pr.id === p.id
                          ? {
                                ...pr,
                                count: pr.count + p.count,
                            }
                          : pr
                  )
                : [...state, p]
        )
    }, [])

    const handleRemoveProduct = useCallback((p: BasketProduct) => {
        setProducts((state) => state.filter((pr) => pr.id !== p.id))
    }, [])

    useEffect(() => {
        if (!isInitiated) {
            return
        }

        products.filter((p) => p.count <= 0).forEach(handleRemoveProduct)

        window?.localStorage?.setItem(
            BASKET_STORAGE_KEY,
            JSON.stringify(products)
        )

        window?.localStorage?.setItem(
            BASKET_INVOICE_STORAGE_KEY,
            JSON.stringify(invoice)
        )
    }, [products, invoice, handleRemoveProduct, isInitiated])

    useEffect(() => {
        tryCatch(() =>
            JSON.parse(
                window?.localStorage?.getItem(BASKET_STORAGE_KEY) || '[]'
            )
        )
            .flatMap(parseSchemaToResultCurry(basketProductsSchema))
            .tap(() => {
                setTimeout(() => {
                    setIsInitiated(true)
                }, 500)
            })
            .flatMap((data) =>
                data.some((p) => !!p.userId && p.userId !== user?.id)
                    ? Result.Error('invalid products for current user')
                    : Result.Ok(data)
            )
            .match({
                Ok: (data) => {
                    setProducts(data)
                },
                Error: (e) => {
                    window?.localStorage?.removeItem(BASKET_STORAGE_KEY)
                    setProducts([])
                },
            })

        tryCatch(() =>
            JSON.parse(
                window?.localStorage?.getItem(BASKET_INVOICE_STORAGE_KEY) ||
                    '{}'
            )
        )
            .flatMap(parseSchemaToResultCurry(basketInvoiceSchema))
            .tap(() => {
                setTimeout(() => {
                    setIsInitiated(true)
                }, 500)
            })
            .flatMap((data) =>
                data?.userId !== user?.id
                    ? Result.Error('invalid basket for current user')
                    : Result.Ok(data)
            )
            .match({
                Ok: (data) => {
                    setInvoice(data)
                },
                Error: (e) => {
                    setInvoice(getDefaultInvoiceDataForUser(userId))
                },
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <BasketContext.Provider
            value={{
                invoice,
                changeInvoice: handleChangeInvoiceData,
                products,
                addProduct: handleAddProduct,
                removeProduct: handleRemoveProduct,
                changeCount: handleChangeCount,
                changeProductPrice: handleChangeProductPrice,
                isInitiated: isInitiated,
                resetProducts: handleResetProducts,
                resetInvoice: handleResetInvoice,
                isLoading,
                setIsLoading,
                note,
                setNote,
                internalOrderNumber,
                setInternalOrderNumber,
            }}
        >
            {children}
        </BasketContext.Provider>
    )
}
