import { DocumentTypeDecoration } from '@graphql-typed-document-node/core'

/* eslint-disable */
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
    [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
    T extends { [key: string]: unknown },
    K extends keyof T,
> = { [_ in K]?: never }
export type Incremental<T> =
    | T
    | {
          [P in keyof T]?: P extends ' $fragmentName' | '__typename'
              ? T[P]
              : never
      }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: { input: string; output: string }
    String: { input: string; output: string }
    Boolean: { input: boolean; output: boolean }
    Int: { input: number; output: number }
    Float: { input: number; output: number }
    DateTime: { input: any; output: any }
    DateTimeMicro: { input: any; output: any }
    DateTimeTZ: { input: any; output: any }
    Decimal: { input: any; output: any }
    Uuid: { input: any; output: any }
}

export type Address = {
    __typename?: 'Address'
    addressId?: Maybe<Scalars['Int']['output']>
    companyRegNumber?: Maybe<Scalars['String']['output']>
    completeStreetCalc?: Maybe<Scalars['String']['output']>
    countryAbbr?: Maybe<Scalars['String']['output']>
    countryId?: Maybe<Scalars['Int']['output']>
    description?: Maybe<Scalars['String']['output']>
    email?: Maybe<Scalars['String']['output']>
    name?: Maybe<Scalars['String']['output']>
    phone?: Maybe<Scalars['String']['output']>
    recordId?: Maybe<Scalars['Int']['output']>
    street?: Maybe<Scalars['String']['output']>
    town?: Maybe<Scalars['String']['output']>
    zip?: Maybe<Scalars['String']['output']>
}

export type BasketItemInput = {
    goodsId: Scalars['Int']['input']
    priceBrutto: Scalars['Decimal']['input']
    priceNetto: Scalars['Decimal']['input']
    quantity: Scalars['Int']['input']
}

export type Batch = {
    __typename?: 'Batch'
    abbr?: Maybe<Scalars['String']['output']>
    articleId?: Maybe<Scalars['Int']['output']>
    articleName?: Maybe<Scalars['String']['output']>
    id?: Maybe<Scalars['Int']['output']>
}

export type BatchFilter = {
    searchBatch?: InputMaybe<LikeOperators>
}

export type BatchK2Page = {
    __typename?: 'BatchK2Page'
    firstPage?: Maybe<Scalars['String']['output']>
    lastPage?: Maybe<Scalars['String']['output']>
    nextPage?: Maybe<Scalars['String']['output']>
    pageRecordCount?: Maybe<Scalars['Int']['output']>
    previousPage?: Maybe<Scalars['String']['output']>
    records: Array<Maybe<Batch>>
}

export type BatchSort = {
    field: BatchSort_Field
    order?: InputMaybe<SortOrder>
}

export enum BatchSort_Field {
    Abbr = 'abbr',
    ArticleId = 'articleId',
    ArticleName = 'articleName',
    Id = 'id',
}

export type BetweenDateTimeOperators = {
    between?: InputMaybe<DateTimeRange>
    isNull?: InputMaybe<Scalars['Boolean']['input']>
}

export type CategoriesConnection = {
    __typename?: 'CategoriesConnection'
    /** Edges of the connection */
    edges?: Maybe<Array<CategoriesConnectionEdge>>
    /** Page info of the connection */
    pageInfo?: Maybe<PageInfo>
    /** Total count of items in the connection. */
    totalCount?: Maybe<Scalars['Int']['output']>
}

export type CategoriesConnectionEdge = {
    __typename?: 'CategoriesConnectionEdge'
    /** The edge cursor */
    cursor: Scalars['String']['output']
    /** Node of the Edge */
    node: Category
}

export type Category = {
    __typename?: 'Category'
    RID: Scalars['String']['output']
    categoryLangItems: Array<Maybe<CategoryLangItem>>
    categoryPathNameCalc: Scalars['String']['output']
    changedAt?: Maybe<Scalars['DateTime']['output']>
    createdAt?: Maybe<Scalars['DateTime']['output']>
    id: Scalars['Uuid']['output']
    importedAt?: Maybe<Scalars['DateTime']['output']>
    name: Scalars['String']['output']
    shopRID: Scalars['String']['output']
}

export type CategoryFilter = {
    RID?: InputMaybe<SimpleStringOperators>
    categoryLangItems_languageId?: InputMaybe<SimpleIntegerOperators>
    categoryLangItems_translatedTitleCalc?: InputMaybe<SimpleStringOperators>
    categoryPathNameCalc?: InputMaybe<SimpleStringOperators>
    id?: InputMaybe<SimpleUuidOperators>
    name?: InputMaybe<SimpleStringOperators>
    parent?: InputMaybe<IsNullOperators>
    parent_id?: InputMaybe<SimpleUuidOperators>
}

export type CategoryLangItem = {
    __typename?: 'CategoryLangItem'
    changedAt?: Maybe<Scalars['DateTime']['output']>
    createdAt?: Maybe<Scalars['DateTime']['output']>
    id: Scalars['Uuid']['output']
    importedAt?: Maybe<Scalars['DateTime']['output']>
    languageId: Scalars['Int']['output']
    shopRID: Scalars['String']['output']
    translatedTitleCalc?: Maybe<Scalars['String']['output']>
}

export type CategorySort = {
    field: CategorySort_Field
    order?: InputMaybe<SortOrder>
}

export enum CategorySort_Field {
    CategoryLangItemsTranslatedTitleCalc = 'categoryLangItems_translatedTitleCalc',
    CategoryPathNameCalc = 'categoryPathNameCalc',
    Name = 'name',
}

export type ChangePasswordInput = {
    GUID?: InputMaybe<Scalars['String']['input']>
    login?: InputMaybe<Scalars['String']['input']>
    password?: InputMaybe<Scalars['String']['input']>
}

export type ChangePasswordMessageInput = {
    username: Scalars['String']['input']
}

export type ChangePasswordMessageResult = {
    __typename?: 'ChangePasswordMessageResult'
    result: Scalars['Boolean']['output']
}

export type ChangePasswordResult = {
    __typename?: 'ChangePasswordResult'
    parameters: Array<ResultParameter>
}

export type Contact = {
    __typename?: 'Contact'
    email?: Maybe<Scalars['String']['output']>
    exPositionE?: Maybe<Scalars['String']['output']>
    exSuperiorEId?: Maybe<Scalars['Int']['output']>
    id?: Maybe<Scalars['Int']['output']>
    name?: Maybe<Scalars['String']['output']>
    phone?: Maybe<Scalars['String']['output']>
    surname?: Maybe<Scalars['String']['output']>
    timeStamp?: Maybe<Scalars['DateTimeMicro']['output']>
}

export type ContactCreateInput = {
    email?: InputMaybe<Scalars['String']['input']>
    name: Scalars['String']['input']
    phone?: InputMaybe<Scalars['String']['input']>
    surname: Scalars['String']['input']
}

export type ContactCreateOrUpdate = {
    __typename?: 'ContactCreateOrUpdate'
    companyName?: Maybe<Scalars['String']['output']>
    companyRID?: Maybe<Scalars['Int']['output']>
    id?: Maybe<Scalars['Int']['output']>
    name?: Maybe<Scalars['String']['output']>
    surname?: Maybe<Scalars['String']['output']>
    timeStamp?: Maybe<Scalars['DateTimeMicro']['output']>
}

export type ContactFilter = {
    examplefilter?: InputMaybe<SimpleBooleanOperators>
}

export type ContactK2Page = {
    __typename?: 'ContactK2Page'
    firstPage?: Maybe<Scalars['String']['output']>
    lastPage?: Maybe<Scalars['String']['output']>
    nextPage?: Maybe<Scalars['String']['output']>
    pageRecordCount?: Maybe<Scalars['Int']['output']>
    previousPage?: Maybe<Scalars['String']['output']>
    records: Array<Maybe<Contact>>
}

export type ContactSort = {
    field: ContactSort_Field
    order?: InputMaybe<SortOrder>
}

export enum ContactSort_Field {
    Surname = 'surname',
    TimeStamp = 'timeStamp',
}

export type ContactUpdateInput = {
    email?: InputMaybe<Scalars['String']['input']>
    id: Scalars['Int']['input']
    name: Scalars['String']['input']
    phone?: InputMaybe<Scalars['String']['input']>
    surname: Scalars['String']['input']
    timeStamp: Scalars['DateTimeMicro']['input']
}

export type CountriesConnection = {
    __typename?: 'CountriesConnection'
    /** Edges of the connection */
    edges?: Maybe<Array<CountriesConnectionEdge>>
    /** Page info of the connection */
    pageInfo?: Maybe<PageInfo>
    /** Total count of items in the connection. */
    totalCount?: Maybe<Scalars['Int']['output']>
}

export type CountriesConnectionEdge = {
    __typename?: 'CountriesConnectionEdge'
    /** The edge cursor */
    cursor: Scalars['String']['output']
    /** Node of the Edge */
    node: Country
}

export type Country = {
    __typename?: 'Country'
    abbr?: Maybe<Scalars['String']['output']>
    changedAt?: Maybe<Scalars['DateTime']['output']>
    countryCode: Scalars['String']['output']
    countryId: Scalars['Int']['output']
    createdAt?: Maybe<Scalars['DateTime']['output']>
    currencyAbbr?: Maybe<Scalars['String']['output']>
    currencyRID?: Maybe<Scalars['String']['output']>
    groupAbbr?: Maybe<Scalars['String']['output']>
    groupId?: Maybe<Scalars['Int']['output']>
    id: Scalars['Uuid']['output']
    importedAt?: Maybe<Scalars['DateTime']['output']>
    name: Scalars['String']['output']
    shopRID: Scalars['String']['output']
}

export type CountryFilter = {
    abbr?: InputMaybe<SimpleStringOperators>
    countryCode?: InputMaybe<SimpleStringOperators>
    countryId?: InputMaybe<SimpleIntegerOperators>
    currencyAbbr?: InputMaybe<SimpleStringOperators>
    currencyRID?: InputMaybe<SimpleStringOperators>
    groupAbbr?: InputMaybe<SimpleStringOperators>
    groupId?: InputMaybe<SimpleIntegerOperators>
    id?: InputMaybe<SimpleUuidOperators>
    name?: InputMaybe<SimpleStringOperators>
}

export type CountrySort = {
    field: CountrySort_Field
    order?: InputMaybe<SortOrder>
}

export enum CountrySort_Field {
    Abbr = 'abbr',
    CountryCode = 'countryCode',
    CountryId = 'countryId',
    CurrencyAbbr = 'currencyAbbr',
    GroupAbbr = 'groupAbbr',
    GroupId = 'groupId',
    Name = 'name',
}

export type DateTimeRange = {
    end: Scalars['DateTime']['input']
    start: Scalars['DateTime']['input']
}

export type DeliveryAddressCreateInput = {
    comment?: InputMaybe<Scalars['String']['input']>
    countryId?: InputMaybe<Scalars['Int']['input']>
    email?: InputMaybe<Scalars['String']['input']>
    identNumber: Scalars['String']['input']
    name: Scalars['String']['input']
    phone?: InputMaybe<Scalars['String']['input']>
    street: Scalars['String']['input']
    taxIdNo?: InputMaybe<Scalars['String']['input']>
    town: Scalars['String']['input']
    vatNumber?: InputMaybe<Scalars['String']['input']>
    zip: Scalars['String']['input']
}

export type DeliveryAddressCreateResult = {
    __typename?: 'DeliveryAddressCreateResult'
    result: Scalars['Boolean']['output']
}

export type DeliveryAddressUpdateInput = {
    addressId?: InputMaybe<Scalars['Int']['input']>
    comment?: InputMaybe<Scalars['String']['input']>
    countryId?: InputMaybe<Scalars['Int']['input']>
    email?: InputMaybe<Scalars['String']['input']>
    identNumber: Scalars['String']['input']
    name: Scalars['String']['input']
    phone?: InputMaybe<Scalars['String']['input']>
    street: Scalars['String']['input']
    taxIdNo?: InputMaybe<Scalars['String']['input']>
    town: Scalars['String']['input']
    vatNumber?: InputMaybe<Scalars['String']['input']>
    zip: Scalars['String']['input']
}

export type DeliveryAddressUpdateResult = {
    __typename?: 'DeliveryAddressUpdateResult'
    result: Scalars['Boolean']['output']
}

export type DeliveryAndPayment = {
    __typename?: 'DeliveryAndPayment'
    RID?: Maybe<Scalars['String']['output']>
    delivery?: Maybe<DeliveryMethod>
    payment?: Maybe<PaymentMethod>
}

export type DeliveryAndPaymentInput = {
    basketItems: Array<BasketItemInput>
    currency: Scalars['String']['input']
    languageId: Scalars['Int']['input']
}

export type DeliveryAndPaymentResult = {
    __typename?: 'DeliveryAndPaymentResult'
    items?: Maybe<Array<Maybe<DeliveryAndPayment>>>
}

export type DeliveryMethod = {
    __typename?: 'DeliveryMethod'
    RID?: Maybe<Scalars['String']['output']>
    id?: Maybe<Scalars['Int']['output']>
    languageTextCalc?: Maybe<Scalars['String']['output']>
    translatedTitleCalc?: Maybe<Scalars['String']['output']>
}

export type DocumentResult = {
    __typename?: 'DocumentResult'
    resultBase64?: Maybe<Scalars['String']['output']>
}

export type EqOrNullIntegerOperators = {
    eqOrNull?: InputMaybe<Scalars['Int']['input']>
}

export type GoodsIdItemInput = {
    goodsId: Scalars['Int']['input']
    quantity: Scalars['Int']['input']
}

export type HealthCheckResult = {
    __typename?: 'HealthCheckResult'
    appEnvironment: Scalars['String']['output']
    environment: Scalars['String']['output']
    status: Scalars['String']['output']
}

export type Image = {
    __typename?: 'Image'
    articleId: Scalars['Int']['output']
    changedAt?: Maybe<Scalars['DateTime']['output']>
    createdAt?: Maybe<Scalars['DateTime']['output']>
    id: Scalars['Uuid']['output']
    importedAt?: Maybe<Scalars['DateTime']['output']>
    shopRID: Scalars['String']['output']
    url: Scalars['String']['output']
}

export type ImportHistory = {
    __typename?: 'ImportHistory'
    changedAt?: Maybe<Scalars['DateTime']['output']>
    cmdArgs: Scalars['String']['output']
    currency?: Maybe<Scalars['String']['output']>
    finishedAt?: Maybe<Scalars['DateTime']['output']>
    id: Scalars['Uuid']['output']
    importedAt?: Maybe<Scalars['DateTime']['output']>
    interruptedAt?: Maybe<Scalars['DateTime']['output']>
    languageId?: Maybe<Scalars['Int']['output']>
    lastException?: Maybe<Scalars['String']['output']>
    lastItemId?: Maybe<Scalars['String']['output']>
    lastLog?: Maybe<Scalars['String']['output']>
    memoryPeak?: Maybe<Scalars['Int']['output']>
    processedItemsCount?: Maybe<Scalars['Int']['output']>
    shopRID: Scalars['String']['output']
    type: ImportTypeEnum
}

export type ImportHistoryConnection = {
    __typename?: 'ImportHistoryConnection'
    /** Edges of the connection */
    edges?: Maybe<Array<ImportHistoryConnectionEdge>>
    /** Page info of the connection */
    pageInfo?: Maybe<PageInfo>
    /** Total count of items in the connection. */
    totalCount?: Maybe<Scalars['Int']['output']>
}

export type ImportHistoryConnectionEdge = {
    __typename?: 'ImportHistoryConnectionEdge'
    /** The edge cursor */
    cursor: Scalars['String']['output']
    /** Node of the Edge */
    node: ImportHistory
}

export type ImportHistoryFilter = {
    currency?: InputMaybe<SimpleStringOperators>
    finishedAt?: InputMaybe<BetweenDateTimeOperators>
    id?: InputMaybe<SimpleUuidOperators>
    importedAt?: InputMaybe<BetweenDateTimeOperators>
    interruptedAt?: InputMaybe<BetweenDateTimeOperators>
    languageId?: InputMaybe<SimpleIntegerOperators>
    lastItemId?: InputMaybe<SimpleStringOperators>
    processedItemsCount?: InputMaybe<SimpleIntegerOperators>
    shopRID?: InputMaybe<SimpleStringOperators>
    type?: InputMaybe<ImportTypeEnumOperators>
}

export type ImportHistorySort = {
    field: ImportHistorySort_Field
    order?: InputMaybe<SortOrder>
}

export enum ImportHistorySort_Field {
    FinishedAt = 'finishedAt',
    ImportedAt = 'importedAt',
    InterruptedAt = 'interruptedAt',
    LanguageId = 'languageId',
    ShopRid = 'shopRID',
    Type = 'type',
}

/** The type of imports for filtering. */
export enum ImportTypeEnum {
    Category = 'CATEGORY',
    Country = 'COUNTRY',
    Product = 'PRODUCT',
    ProductsInCategories = 'PRODUCTS_IN_CATEGORIES',
}

export type ImportTypeEnumOperators = {
    eq?: InputMaybe<ImportTypeEnum>
}

export type InferiorOrder = {
    __typename?: 'InferiorOrder'
    RID?: Maybe<Scalars['String']['output']>
    amountGrossCCalc?: Maybe<Scalars['Decimal']['output']>
    amountNetC?: Maybe<Scalars['Decimal']['output']>
    amountVATCCalc?: Maybe<Scalars['Decimal']['output']>
    contactPersonFullNameTitleCalc?: Maybe<Scalars['String']['output']>
    currency?: Maybe<Scalars['String']['output']>
    deliveryAddressFullNameCalc?: Maybe<Scalars['String']['output']>
    documentIdentificationCalc?: Maybe<Scalars['String']['output']>
    fullDeliveryAddressIdCalcOneLineCalc?: Maybe<Scalars['String']['output']>
    issueDate?: Maybe<Scalars['DateTimeTZ']['output']>
    orderItems?: Maybe<Array<Maybe<OrderItem>>>
    paymentMethodRIDCalcAbbr?: Maybe<Scalars['String']['output']>
    paymentMethodRIDCalcRID?: Maybe<Scalars['String']['output']>
    statusId?: Maybe<Scalars['Int']['output']>
    statusIdAbbr?: Maybe<Scalars['String']['output']>
    statusIdLanguageTextCalc?: Maybe<Scalars['String']['output']>
    timeStamp?: Maybe<Scalars['DateTimeMicro']['output']>
    tradingPartnerAddressId?: Maybe<Scalars['Int']['output']>
    tradingPartnerId?: Maybe<Scalars['Int']['output']>
    tradingPartnerName?: Maybe<Scalars['String']['output']>
    transportMethodRIDCalcAbbr?: Maybe<Scalars['String']['output']>
    transportMethodRIDCalcRID?: Maybe<Scalars['String']['output']>
}

export type InferiorOrderFilter = {
    orderStatus?: InputMaybe<OrderStatusEnumOperators>
    searchSurname?: InputMaybe<LikeOperators>
}

export type InferiorOrderK2Page = {
    __typename?: 'InferiorOrderK2Page'
    firstPage?: Maybe<Scalars['String']['output']>
    lastPage?: Maybe<Scalars['String']['output']>
    nextPage?: Maybe<Scalars['String']['output']>
    pageRecordCount?: Maybe<Scalars['Int']['output']>
    previousPage?: Maybe<Scalars['String']['output']>
    records: Array<Maybe<InferiorOrder>>
}

export type InferiorOrderSort = {
    field: InferiorOrderSort_Field
    order?: InputMaybe<SortOrder>
}

export enum InferiorOrderSort_Field {
    IssueDate = 'issueDate',
    TimeStamp = 'timeStamp',
}

export type InferiorUser = {
    __typename?: 'InferiorUser'
    firstName?: Maybe<Scalars['String']['output']>
    login?: Maybe<Scalars['String']['output']>
    phone?: Maybe<Scalars['String']['output']>
    rid: Scalars['String']['output']
    statusAbbr?: Maybe<Scalars['String']['output']>
    statusCodeTypeId?: Maybe<Scalars['Int']['output']>
    surname?: Maybe<Scalars['String']['output']>
}

export type InferiorUserFilter = {
    blocked?: InputMaybe<SimpleBooleanOperators>
}

export type InferiorUserK2Page = {
    __typename?: 'InferiorUserK2Page'
    firstPage?: Maybe<Scalars['String']['output']>
    lastPage?: Maybe<Scalars['String']['output']>
    nextPage?: Maybe<Scalars['String']['output']>
    pageRecordCount?: Maybe<Scalars['Int']['output']>
    previousPage?: Maybe<Scalars['String']['output']>
    records: Array<Maybe<InferiorUser>>
}

export type InferiorUserSort = {
    field: InferiorUserSort_Field
    order?: InputMaybe<SortOrder>
}

export enum InferiorUserSort_Field {
    Surname = 'surname',
}

export type Invoice = {
    __typename?: 'Invoice'
    RID?: Maybe<Scalars['String']['output']>
    amountGrossC?: Maybe<Scalars['Decimal']['output']>
    amountNetC?: Maybe<Scalars['Decimal']['output']>
    amountVATCCalc?: Maybe<Scalars['Decimal']['output']>
    confirmedOrCanceled2IdCalcDescription?: Maybe<Scalars['String']['output']>
    currency?: Maybe<Scalars['String']['output']>
    documentIdentificationCalc?: Maybe<Scalars['String']['output']>
    dueDate?: Maybe<Scalars['DateTimeTZ']['output']>
    paidStateIdCalcDescription?: Maybe<Scalars['String']['output']>
    payOffAmountCCalc?: Maybe<Scalars['Decimal']['output']>
    referenceNumber?: Maybe<Scalars['String']['output']>
    taxPointDate?: Maybe<Scalars['DateTimeTZ']['output']>
}

export type InvoiceFilter = {
    overdueInvoices?: InputMaybe<SimpleBooleanOperators>
    sentInvoices?: InputMaybe<SimpleBooleanOperators>
    unpaidInvoices?: InputMaybe<SimpleBooleanOperators>
}

export type InvoiceK2Page = {
    __typename?: 'InvoiceK2Page'
    firstPage?: Maybe<Scalars['String']['output']>
    lastPage?: Maybe<Scalars['String']['output']>
    nextPage?: Maybe<Scalars['String']['output']>
    pageRecordCount?: Maybe<Scalars['Int']['output']>
    previousPage?: Maybe<Scalars['String']['output']>
    records: Array<Maybe<Invoice>>
}

export type InvoiceSort = {
    field: InvoiceSort_Field
    order?: InputMaybe<SortOrder>
}

export enum InvoiceSort_Field {
    DueDate = 'dueDate',
    TaxPointDate = 'taxPointDate',
}

export type IsNullOperators = {
    isNull?: InputMaybe<Scalars['Boolean']['input']>
}

export type LikeOperators = {
    like?: InputMaybe<Scalars['String']['input']>
}

export type LogoutResult = {
    __typename?: 'LogoutResult'
    result: Scalars['Boolean']['output']
}

export type Order = {
    __typename?: 'Order'
    RID?: Maybe<Scalars['String']['output']>
    amountGrossCCalc?: Maybe<Scalars['Decimal']['output']>
    amountNetC?: Maybe<Scalars['Decimal']['output']>
    amountVATCCalc?: Maybe<Scalars['Decimal']['output']>
    contactPersonFullNameTitleCalc?: Maybe<Scalars['String']['output']>
    currency?: Maybe<Scalars['String']['output']>
    deliveryAddressFullNameCalc?: Maybe<Scalars['String']['output']>
    documentIdentificationCalc?: Maybe<Scalars['String']['output']>
    fullDeliveryAddressIdCalcOneLineCalc?: Maybe<Scalars['String']['output']>
    issueDate?: Maybe<Scalars['DateTimeTZ']['output']>
    orderItems?: Maybe<Array<Maybe<OrderItem>>>
    paymentMethodRIDCalcAbbr?: Maybe<Scalars['String']['output']>
    paymentMethodRIDCalcRID?: Maybe<Scalars['String']['output']>
    statusId?: Maybe<Scalars['Int']['output']>
    statusIdAbbr?: Maybe<Scalars['String']['output']>
    statusIdLanguageTextCalc?: Maybe<Scalars['String']['output']>
    timeStamp?: Maybe<Scalars['DateTimeMicro']['output']>
    tradingPartnerAddressId?: Maybe<Scalars['Int']['output']>
    tradingPartnerId?: Maybe<Scalars['Int']['output']>
    tradingPartnerName?: Maybe<Scalars['String']['output']>
    transportMethodRIDCalcAbbr?: Maybe<Scalars['String']['output']>
    transportMethodRIDCalcRID?: Maybe<Scalars['String']['output']>
}

export type OrderApproveInput = {
    RIDApproveOrder?: InputMaybe<Scalars['String']['input']>
}

export type OrderApproveResult = {
    __typename?: 'OrderApproveResult'
    parameters: Array<ResultParameter>
}

export type OrderCreateInput = {
    additionalInfo: Scalars['String']['input']
    additionalInformation: Scalars['String']['input']
    basketItems: Array<BasketItemInput>
    conEmail: Scalars['String']['input']
    conFirstName: Scalars['String']['input']
    conLastName: Scalars['String']['input']
    conPhoneNr: Scalars['String']['input']
    currencyStr: Scalars['String']['input']
    delCountry: Scalars['Int']['input']
    delEmail: Scalars['String']['input']
    delPhoneNr: Scalars['String']['input']
    delPostCode: Scalars['String']['input']
    delStreet: Scalars['String']['input']
    delTown: Scalars['String']['input']
    orderNumber?: InputMaybe<Scalars['String']['input']>
    paymentMethodId: Scalars['String']['input']
    sendByParts: Scalars['Boolean']['input']
    transportMethodId: Scalars['String']['input']
}

export type OrderCreateResult = {
    __typename?: 'OrderCreateResult'
    RID?: Maybe<Scalars['String']['output']>
}

export type OrderDocumentInput = {
    batchAbbr?: InputMaybe<Scalars['String']['input']>
    batchId?: InputMaybe<Scalars['Int']['input']>
    invoiceOutRID?: InputMaybe<Scalars['String']['input']>
    lang: Scalars['Int']['input']
    releaseNoteItemsRID?: InputMaybe<Scalars['String']['input']>
    saleDocRID?: InputMaybe<Scalars['String']['input']>
    typeOfDoc: OrderDocumentTypeEnum
}

/** The types of order documents. */
export enum OrderDocumentTypeEnum {
    Certificate = 'CERTIFICATE',
    Invoice = 'INVOICE',
    OrderConfirmation = 'ORDER_CONFIRMATION',
}

export type OrderFilter = {
    orderStatus?: InputMaybe<OrderStatusEnumOperators>
    searchSurname?: InputMaybe<LikeOperators>
}

export type OrderItem = {
    __typename?: 'OrderItem'
    accountingTotalPriceVATCCalc?: Maybe<Scalars['Decimal']['output']>
    articleAbbr?: Maybe<Scalars['String']['output']>
    articleCategoryAbbr?: Maybe<Scalars['String']['output']>
    articleCfQuality?: Maybe<Scalars['String']['output']>
    articleId?: Maybe<Scalars['Int']['output']>
    articleName?: Maybe<Scalars['String']['output']>
    articleSortingNameLang?: Maybe<Scalars['String']['output']>
    articleUnit?: Maybe<Scalars['String']['output']>
    batchAbbr?: Maybe<Scalars['String']['output']>
    batchId?: Maybe<Scalars['Int']['output']>
    invoiceOutRID?: Maybe<Scalars['String']['output']>
    invoicePriceVATCalc?: Maybe<Scalars['Decimal']['output']>
    invoiceTotalPriceGrossCCalc?: Maybe<Scalars['Decimal']['output']>
    invoiceTotalPriceNetCCalc?: Maybe<Scalars['Decimal']['output']>
    invoiceUnitPriceGrossCCalc?: Maybe<Scalars['Decimal']['output']>
    invoiceUnitPriceNetCCalc?: Maybe<Scalars['Decimal']['output']>
    itemStatus?: Maybe<Scalars['String']['output']>
    quantityUMCalc?: Maybe<Scalars['Int']['output']>
}

export type OrderK2Page = {
    __typename?: 'OrderK2Page'
    firstPage?: Maybe<Scalars['String']['output']>
    lastPage?: Maybe<Scalars['String']['output']>
    nextPage?: Maybe<Scalars['String']['output']>
    pageRecordCount?: Maybe<Scalars['Int']['output']>
    previousPage?: Maybe<Scalars['String']['output']>
    records: Array<Maybe<Order>>
}

export type OrderSort = {
    field: OrderSort_Field
    order?: InputMaybe<SortOrder>
}

export enum OrderSort_Field {
    IssueDate = 'issueDate',
    TimeStamp = 'timeStamp',
}

/** The status of orders for filtering. */
export enum OrderStatusEnum {
    Approved = 'APPROVED',
    Canceled = 'CANCELED',
    Closed = 'CLOSED',
    ForApproval = 'FOR_APPROVAL',
    New = 'NEW',
    NotClosed = 'NOT_CLOSED',
    Open = 'OPEN',
    Rejected = 'REJECTED',
}

export type OrderStatusEnumOperators = {
    eq?: InputMaybe<OrderStatusEnum>
}

export type OrderStatusUpdate = {
    __typename?: 'OrderStatusUpdate'
    RID?: Maybe<Scalars['String']['output']>
    description?: Maybe<Scalars['String']['output']>
    statusId?: Maybe<Scalars['Int']['output']>
    statusIdAbbr?: Maybe<Scalars['String']['output']>
    timeStamp?: Maybe<Scalars['DateTimeMicro']['output']>
}

/** The status of orders for status update. */
export enum OrderStatusUpdateEnum {
    Rejected = 'REJECTED',
}

export type OrderStatusUpdateInput = {
    orderRID: Scalars['String']['input']
    statusId: OrderStatusUpdateEnum
    timeStamp: Scalars['DateTimeMicro']['input']
}

/** Information about pagination in a connection. */
export type PageInfo = {
    __typename?: 'PageInfo'
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output']
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output']
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>
}

export type PaymentMethod = {
    __typename?: 'PaymentMethod'
    RID?: Maybe<Scalars['String']['output']>
    id?: Maybe<Scalars['Int']['output']>
    languageTextCalc?: Maybe<Scalars['String']['output']>
    translatedTitleCalc?: Maybe<Scalars['String']['output']>
}

export type PriceInput = {
    articleId?: InputMaybe<Scalars['Int']['input']>
}

export type PriceResult = {
    __typename?: 'PriceResult'
    parameters: Array<ResultParameter>
}

export type PricesAndAvailabilityInput = {
    brand?: InputMaybe<Scalars['String']['input']>
    currency: Scalars['String']['input']
    languageId: Scalars['Int']['input']
    quality?: InputMaybe<Scalars['String']['input']>
    sortingName: Scalars['String']['input']
}

export type PricesAndAvailabilityProduct = {
    __typename?: 'PricesAndAvailabilityProduct'
    abbr?: Maybe<Scalars['String']['output']>
    acros?: Maybe<Scalars['Boolean']['output']>
    articleId?: Maybe<Scalars['Int']['output']>
    available?: Maybe<Scalars['String']['output']>
    brand?: Maybe<Scalars['String']['output']>
    color?: Maybe<Scalars['Int']['output']>
    colorHexTxt?: Maybe<Scalars['String']['output']>
    contactPersonId?: Maybe<Scalars['Int']['output']>
    currency?: Maybe<Scalars['String']['output']>
    name?: Maybe<Scalars['String']['output']>
    onStock?: Maybe<Scalars['Boolean']['output']>
    partnerId?: Maybe<Scalars['Int']['output']>
    priceBrutto?: Maybe<Scalars['Decimal']['output']>
    priceNetto?: Maybe<Scalars['Decimal']['output']>
    quality?: Maybe<Scalars['String']['output']>
    sortingName?: Maybe<Scalars['String']['output']>
}

export type PricesAndAvailabilityResult = {
    __typename?: 'PricesAndAvailabilityResult'
    parameters: Array<ResultParameter>
    products?: Maybe<Array<Maybe<PricesAndAvailabilityProduct>>>
}

export type Product = {
    __typename?: 'Product'
    CAS?: Maybe<Scalars['String']['output']>
    CFSentences?: Maybe<Scalars['String']['output']>
    CFSymbols?: Maybe<Scalars['String']['output']>
    EINECS?: Maybe<Scalars['String']['output']>
    RTECS?: Maybe<Scalars['String']['output']>
    abbr?: Maybe<Scalars['String']['output']>
    articleCategory?: Maybe<Scalars['String']['output']>
    articleId: Scalars['Int']['output']
    brandRID?: Maybe<Scalars['String']['output']>
    categoriesImportedAt?: Maybe<Scalars['DateTime']['output']>
    changedAt?: Maybe<Scalars['DateTime']['output']>
    createdAt?: Maybe<Scalars['DateTime']['output']>
    id: Scalars['Uuid']['output']
    images: Array<Maybe<Image>>
    importedAt?: Maybe<Scalars['DateTime']['output']>
    productGroup?: Maybe<ProductGroup>
    productLangItems: Array<ProductLangItem>
    productPrices: Array<ProductPrice>
    qualityAbbr?: Maybe<Scalars['String']['output']>
    relMolWeight?: Maybe<Scalars['String']['output']>
    safetyDataSheets: Array<Maybe<SafetyDataSheet>>
    shopRID: Scalars['String']['output']
    specifications: Array<Maybe<Specification>>
    unitCodeTypeId?: Maybe<Scalars['Int']['output']>
    vatCoefficient?: Maybe<Scalars['Decimal']['output']>
}

export type ProductAvailabilityInput = {
    goodsId: Scalars['Int']['input']
    langId: Scalars['Int']['input']
    quantity: Scalars['Int']['input']
}

export type ProductAvailabilityResult = {
    __typename?: 'ProductAvailabilityResult'
    parameters: Array<ResultParameter>
}

export type ProductDocumentInput = {
    batchId?: InputMaybe<Scalars['Int']['input']>
    documentId?: InputMaybe<Scalars['Int']['input']>
    goodsAbbr?: InputMaybe<Scalars['String']['input']>
    goodsId?: InputMaybe<Scalars['Int']['input']>
    lang: Scalars['Int']['input']
    symbol?: InputMaybe<Scalars['String']['input']>
    typeOfDoc: ProductDocumentTypeEnum
}

/** The types of product documents. */
export enum ProductDocumentTypeEnum {
    Certificate = 'CERTIFICATE',
    SafetyDataSheet = 'SAFETY_DATA_SHEET',
    SafetySymbol = 'SAFETY_SYMBOL',
    Specification = 'SPECIFICATION',
}

export type ProductFilter = {
    articleId?: InputMaybe<SimpleIntegerOperators>
    brandRID?: InputMaybe<SimpleStringOperators>
    categories_id?: InputMaybe<SimpleUuidOperators>
    id?: InputMaybe<SimpleUuidOperators>
    productGroup_firstLetter?: InputMaybe<SimpleStringOperators>
    productGroup_id?: InputMaybe<SimpleUuidOperators>
    productLangItems_languageId?: InputMaybe<SimpleIntegerOperators>
    productPrices_currency?: InputMaybe<SimpleStringOperators>
    safetyDataSheets_languageId?: InputMaybe<EqOrNullIntegerOperators>
    specifications_languageId?: InputMaybe<EqOrNullIntegerOperators>
    unitCodeTypeId?: InputMaybe<SimpleIntegerOperators>
}

export type ProductGroup = {
    __typename?: 'ProductGroup'
    changedAt?: Maybe<Scalars['DateTime']['output']>
    createdAt?: Maybe<Scalars['DateTime']['output']>
    firstLetter: Scalars['String']['output']
    id: Scalars['Uuid']['output']
    importedAt?: Maybe<Scalars['DateTime']['output']>
    languageId: Scalars['Int']['output']
    shopRID: Scalars['String']['output']
    sortingNameLang: Scalars['String']['output']
}

export type ProductGroupFilter = {
    id?: InputMaybe<SimpleUuidOperators>
    languageId?: InputMaybe<SimpleIntegerOperators>
    products_categories_id?: InputMaybe<SimpleUuidOperators>
    sortingNameLang?: InputMaybe<SimpleStringOperators>
}

export type ProductGroupSort = {
    field: ProductGroupSort_Field
    order?: InputMaybe<SortOrder>
}

export enum ProductGroupSort_Field {
    SortingNameLang = 'sortingNameLang',
}

export type ProductGroupsConnection = {
    __typename?: 'ProductGroupsConnection'
    /** Edges of the connection */
    edges?: Maybe<Array<ProductGroupsConnectionEdge>>
    /** Page info of the connection */
    pageInfo?: Maybe<PageInfo>
    /** Total count of items in the connection. */
    totalCount?: Maybe<Scalars['Int']['output']>
}

export type ProductGroupsConnectionEdge = {
    __typename?: 'ProductGroupsConnectionEdge'
    /** The edge cursor */
    cursor: Scalars['String']['output']
    /** Node of the Edge */
    node: ProductGroup
}

export type ProductLangItem = {
    __typename?: 'ProductLangItem'
    CFQuality?: Maybe<Scalars['String']['output']>
    appearanceLang?: Maybe<Scalars['String']['output']>
    articleId: Scalars['Int']['output']
    boilingPoint?: Maybe<Scalars['String']['output']>
    brandName?: Maybe<Scalars['String']['output']>
    changedAt?: Maybe<Scalars['DateTime']['output']>
    createdAt?: Maybe<Scalars['DateTime']['output']>
    id: Scalars['Uuid']['output']
    importedAt?: Maybe<Scalars['DateTime']['output']>
    languageId: Scalars['Int']['output']
    languageTextCalc?: Maybe<Scalars['String']['output']>
    meltingPoint?: Maybe<Scalars['String']['output']>
    shopRID: Scalars['String']['output']
    storageCondLang?: Maybe<Scalars['String']['output']>
    synonymLang?: Maybe<Scalars['String']['output']>
    unit?: Maybe<Scalars['String']['output']>
    usebilityLang?: Maybe<Scalars['String']['output']>
}

export type ProductPrice = {
    __typename?: 'ProductPrice'
    articleId: Scalars['Int']['output']
    basePrice: Scalars['Decimal']['output']
    basePriceNet: Scalars['Decimal']['output']
    changedAt?: Maybe<Scalars['DateTime']['output']>
    createdAt?: Maybe<Scalars['DateTime']['output']>
    currency: Scalars['String']['output']
    id: Scalars['Uuid']['output']
    importedAt?: Maybe<Scalars['DateTime']['output']>
    shopRID: Scalars['String']['output']
}

export type ProductSort = {
    field: ProductSort_Field
    order?: InputMaybe<SortOrder>
}

export enum ProductSort_Field {
    Abbr = 'abbr',
    ArticleId = 'articleId',
    ProductGroupSortingNameLang = 'productGroup_sortingNameLang',
    ProductLangItemsCfQuality = 'productLangItems_CFQuality',
    ProductLangItemsBrandName = 'productLangItems_brandName',
    ProductLangItemsUnit = 'productLangItems_unit',
    ProductPricesBasePrice = 'productPrices_basePrice',
    ProductPricesBasePriceNet = 'productPrices_basePriceNet',
    VatCoefficient = 'vatCoefficient',
}

export type ProductsConnection = {
    __typename?: 'ProductsConnection'
    /** Edges of the connection */
    edges?: Maybe<Array<ProductsConnectionEdge>>
    /** Page info of the connection */
    pageInfo?: Maybe<PageInfo>
    /** Total count of items in the connection. */
    totalCount?: Maybe<Scalars['Int']['output']>
}

export type ProductsConnectionEdge = {
    __typename?: 'ProductsConnectionEdge'
    /** The edge cursor */
    cursor: Scalars['String']['output']
    /** Node of the Edge */
    node: Product
}

export type ResultParameter = {
    __typename?: 'ResultParameter'
    description: Scalars['String']['output']
    name: Scalars['String']['output']
    value: Scalars['String']['output']
}

export type RootMutation = {
    __typename?: 'RootMutation'
    changePassword: ChangePasswordResult
    changePasswordMessage: ChangePasswordMessageResult
    contactCreate: ContactCreateOrUpdate
    contactUpdate: ContactCreateOrUpdate
    deliveryAddressCreate: DeliveryAddressCreateResult
    deliveryAddressUpdate: DeliveryAddressUpdateResult
    orderApprove: OrderApproveResult
    orderCreate: OrderCreateResult
    orderStatusUpdate: OrderStatusUpdate
    userBlock: UserBlockResult
    userCreate: UserCreateResult
    userCreateBusiness: UserCreateResult
    userUnblock: UserUnblockResult
    userUpdate: UserUpdateResult
}

export type RootMutationChangePasswordArgs = {
    input?: InputMaybe<ChangePasswordInput>
}

export type RootMutationChangePasswordMessageArgs = {
    input?: InputMaybe<ChangePasswordMessageInput>
}

export type RootMutationContactCreateArgs = {
    input?: InputMaybe<ContactCreateInput>
}

export type RootMutationContactUpdateArgs = {
    input?: InputMaybe<ContactUpdateInput>
}

export type RootMutationDeliveryAddressCreateArgs = {
    input?: InputMaybe<DeliveryAddressCreateInput>
}

export type RootMutationDeliveryAddressUpdateArgs = {
    input?: InputMaybe<DeliveryAddressUpdateInput>
}

export type RootMutationOrderApproveArgs = {
    input?: InputMaybe<OrderApproveInput>
}

export type RootMutationOrderCreateArgs = {
    input?: InputMaybe<OrderCreateInput>
}

export type RootMutationOrderStatusUpdateArgs = {
    input?: InputMaybe<OrderStatusUpdateInput>
}

export type RootMutationUserBlockArgs = {
    input?: InputMaybe<UserBlockInput>
}

export type RootMutationUserCreateArgs = {
    input?: InputMaybe<UserCreateInput>
}

export type RootMutationUserCreateBusinessArgs = {
    input?: InputMaybe<UserCreateBusinessInput>
}

export type RootMutationUserUnblockArgs = {
    input?: InputMaybe<UserUnblockInput>
}

export type RootMutationUserUpdateArgs = {
    input?: InputMaybe<UserUpdateInput>
}

export type RootQuery = {
    __typename?: 'RootQuery'
    /** List K2 batches by input filter and sort result by sorting criteria */
    batches?: Maybe<BatchK2Page>
    /** List product categories by input filter and sort result by sorting criteria */
    categories?: Maybe<CategoriesConnection>
    /** List K2 contact persons of user by input filter and sort result by sorting criteria */
    contacts?: Maybe<ContactK2Page>
    /** List Countries by input filter and sort result by sorting criteria */
    countries?: Maybe<CountriesConnection>
    /** Get logged-in user data from K2. */
    currentUser?: Maybe<User>
    /** Get delivery and payment methods. */
    deliveryAndPayment: DeliveryAndPaymentResult
    /** Query to verify GraphQL API status. Call without caching header. */
    healthCheck?: Maybe<HealthCheckResult>
    /** List the history of imports by the input filter and sort the result by the sorting criteria */
    importHistory?: Maybe<ImportHistoryConnection>
    /** List K2 orders of inferior users by input filter and sort result by sorting criteria */
    inferiorOrders?: Maybe<InferiorOrderK2Page>
    /** List K2 inferior users by input filter and sort result by sorting criteria */
    inferiorUsers?: Maybe<InferiorUserK2Page>
    /** List K2 invoices by input filter and sort result by sorting criteria */
    invoices?: Maybe<InvoiceK2Page>
    /** Log out the current user */
    logout?: Maybe<LogoutResult>
    /** Get document file according type for logged-in user */
    orderDocument?: Maybe<DocumentResult>
    /** List K2 orders of logged-in user by input filter and sort result by sorting criteria */
    orders?: Maybe<OrderK2Page>
    /** Get product price for contact person. */
    price?: Maybe<PriceResult>
    /** Get prices and availability of products. */
    pricesAndAvailability?: Maybe<PricesAndAvailabilityResult>
    /** Get prices and availability of products for user. */
    pricesAndAvailabilityUser?: Maybe<PricesAndAvailabilityResult>
    /** Get one instance of Product by primary key. */
    product?: Maybe<Product>
    /** Get product availability. */
    productAvailability?: Maybe<ProductAvailabilityResult>
    /** Get document file according type. */
    productDocument?: Maybe<DocumentResult>
    /** List ProductGroups by input filter and sort result by sorting criteria */
    productGroups?: Maybe<ProductGroupsConnection>
    /** List Products by input filter and sort result by sorting criteria */
    products?: Maybe<ProductsConnection>
    /** List of products retrieved from Elasticsearch by search term */
    searchProducts?: Maybe<SearchProductsConnection>
    /** Get transport cost. */
    transportCost: TransportCostResult
}

export type RootQueryBatchesArgs = {
    filter?: InputMaybe<Array<BatchFilter>>
    page?: InputMaybe<Scalars['String']['input']>
    pageSize?: InputMaybe<Scalars['Int']['input']>
    sort?: InputMaybe<Array<BatchSort>>
}

export type RootQueryCategoriesArgs = {
    after?: InputMaybe<Scalars['String']['input']>
    before?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<Array<CategoryFilter>>
    first?: InputMaybe<Scalars['Int']['input']>
    last?: InputMaybe<Scalars['Int']['input']>
    sort?: InputMaybe<Array<CategorySort>>
}

export type RootQueryContactsArgs = {
    filter?: InputMaybe<Array<ContactFilter>>
    page?: InputMaybe<Scalars['String']['input']>
    pageSize?: InputMaybe<Scalars['Int']['input']>
    sort?: InputMaybe<Array<ContactSort>>
}

export type RootQueryCountriesArgs = {
    after?: InputMaybe<Scalars['String']['input']>
    before?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<Array<CountryFilter>>
    first?: InputMaybe<Scalars['Int']['input']>
    last?: InputMaybe<Scalars['Int']['input']>
    sort?: InputMaybe<Array<CountrySort>>
}

export type RootQueryDeliveryAndPaymentArgs = {
    input: DeliveryAndPaymentInput
}

export type RootQueryImportHistoryArgs = {
    after?: InputMaybe<Scalars['String']['input']>
    before?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<Array<ImportHistoryFilter>>
    first?: InputMaybe<Scalars['Int']['input']>
    last?: InputMaybe<Scalars['Int']['input']>
    sort?: InputMaybe<Array<ImportHistorySort>>
}

export type RootQueryInferiorOrdersArgs = {
    filter?: InputMaybe<Array<InferiorOrderFilter>>
    page?: InputMaybe<Scalars['String']['input']>
    pageSize?: InputMaybe<Scalars['Int']['input']>
    sort?: InputMaybe<Array<InferiorOrderSort>>
}

export type RootQueryInferiorUsersArgs = {
    filter?: InputMaybe<Array<InferiorUserFilter>>
    page?: InputMaybe<Scalars['String']['input']>
    pageSize?: InputMaybe<Scalars['Int']['input']>
    sort?: InputMaybe<Array<InferiorUserSort>>
}

export type RootQueryInvoicesArgs = {
    filter?: InputMaybe<Array<InvoiceFilter>>
    page?: InputMaybe<Scalars['String']['input']>
    pageSize?: InputMaybe<Scalars['Int']['input']>
    sort?: InputMaybe<Array<InvoiceSort>>
}

export type RootQueryOrderDocumentArgs = {
    input: OrderDocumentInput
}

export type RootQueryOrdersArgs = {
    filter?: InputMaybe<Array<OrderFilter>>
    page?: InputMaybe<Scalars['String']['input']>
    pageSize?: InputMaybe<Scalars['Int']['input']>
    sort?: InputMaybe<Array<OrderSort>>
}

export type RootQueryPriceArgs = {
    input: PriceInput
}

export type RootQueryPricesAndAvailabilityArgs = {
    input: PricesAndAvailabilityInput
}

export type RootQueryPricesAndAvailabilityUserArgs = {
    input: PricesAndAvailabilityInput
}

export type RootQueryProductArgs = {
    id: Scalars['Uuid']['input']
    languageId?: InputMaybe<Scalars['Int']['input']>
}

export type RootQueryProductAvailabilityArgs = {
    input: ProductAvailabilityInput
}

export type RootQueryProductDocumentArgs = {
    input: ProductDocumentInput
}

export type RootQueryProductGroupsArgs = {
    after?: InputMaybe<Scalars['String']['input']>
    before?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<Array<ProductGroupFilter>>
    first?: InputMaybe<Scalars['Int']['input']>
    last?: InputMaybe<Scalars['Int']['input']>
    sort?: InputMaybe<Array<ProductGroupSort>>
}

export type RootQueryProductsArgs = {
    after?: InputMaybe<Scalars['String']['input']>
    before?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<Array<ProductFilter>>
    first?: InputMaybe<Scalars['Int']['input']>
    last?: InputMaybe<Scalars['Int']['input']>
    sort?: InputMaybe<Array<ProductSort>>
}

export type RootQuerySearchProductsArgs = {
    after?: InputMaybe<Scalars['String']['input']>
    before?: InputMaybe<Scalars['String']['input']>
    filter: Array<SearchProductFilter>
    first?: InputMaybe<Scalars['Int']['input']>
    last?: InputMaybe<Scalars['Int']['input']>
}

export type RootQueryTransportCostArgs = {
    input: TransportCostInput
}

export type SafetyDataSheet = {
    __typename?: 'SafetyDataSheet'
    articleId: Scalars['Int']['output']
    changedAt?: Maybe<Scalars['DateTime']['output']>
    createdAt?: Maybe<Scalars['DateTime']['output']>
    id: Scalars['Uuid']['output']
    importedAt?: Maybe<Scalars['DateTime']['output']>
    languageId: Scalars['Int']['output']
    shopRID: Scalars['String']['output']
    url: Scalars['String']['output']
}

export type SearchProductFilter = {
    languageId?: InputMaybe<SimpleIntegerOperators>
    term?: InputMaybe<SimpleStringOperators>
}

export type SearchProductsConnection = {
    __typename?: 'SearchProductsConnection'
    /** Edges of the connection */
    edges?: Maybe<Array<ProductsConnectionEdge>>
    /** Page info of the connection */
    pageInfo?: Maybe<PageInfo>
    /** Total count of items in the connection. */
    totalCount?: Maybe<Scalars['Int']['output']>
}

export type SimpleBooleanOperators = {
    eq?: InputMaybe<Scalars['Boolean']['input']>
}

export type SimpleIntegerOperators = {
    eq?: InputMaybe<Scalars['Int']['input']>
}

export type SimpleStringOperators = {
    eq?: InputMaybe<Scalars['String']['input']>
}

export type SimpleUuidOperators = {
    eq?: InputMaybe<Scalars['Uuid']['input']>
}

/** Sort order */
export enum SortOrder {
    Asc = 'ASC',
    Desc = 'DESC',
}

export type Specification = {
    __typename?: 'Specification'
    articleId: Scalars['Int']['output']
    changedAt?: Maybe<Scalars['DateTime']['output']>
    createdAt?: Maybe<Scalars['DateTime']['output']>
    id: Scalars['Uuid']['output']
    importedAt?: Maybe<Scalars['DateTime']['output']>
    languageId: Scalars['Int']['output']
    shopRID: Scalars['String']['output']
    url: Scalars['String']['output']
}

export type TransportCostInput = {
    currency: Scalars['String']['input']
    goodsIdList: Array<GoodsIdItemInput>
    transportId: Scalars['String']['input']
    zip: Scalars['String']['input']
}

export type TransportCostResult = {
    __typename?: 'TransportCostResult'
    parameters: Array<ResultParameter>
}

export type User = {
    __typename?: 'User'
    companyName?: Maybe<Scalars['String']['output']>
    companyRegNumber?: Maybe<Scalars['String']['output']>
    deliveryAddresses?: Maybe<Array<Maybe<Address>>>
    eshopEmailCalc?: Maybe<Scalars['String']['output']>
    eshopPhoneCalc?: Maybe<Scalars['String']['output']>
    hasSuperiorPerson: Scalars['Boolean']['output']
    invoiceAddress?: Maybe<Address>
    loginNameCalc: Scalars['String']['output']
    name?: Maybe<Scalars['String']['output']>
    surname?: Maybe<Scalars['String']['output']>
    vatRegNumber?: Maybe<Scalars['String']['output']>
}

export type UserBlockInput = {
    registrationRID?: InputMaybe<Scalars['String']['input']>
}

export type UserBlockResult = {
    __typename?: 'UserBlockResult'
    parameters: Array<ResultParameter>
}

export type UserCreateBusinessInput = {
    countryId?: InputMaybe<Scalars['Int']['input']>
    email: Scalars['String']['input']
    firmName?: InputMaybe<Scalars['String']['input']>
    firstName: Scalars['String']['input']
    identNumber?: InputMaybe<Scalars['String']['input']>
    lastName: Scalars['String']['input']
    loginName: Scalars['String']['input']
    phoneNo: Scalars['String']['input']
    postCode?: InputMaybe<Scalars['String']['input']>
    street?: InputMaybe<Scalars['String']['input']>
    taxNumber?: InputMaybe<Scalars['String']['input']>
    town?: InputMaybe<Scalars['String']['input']>
    vatNumber?: InputMaybe<Scalars['String']['input']>
}

export type UserCreateInput = {
    countryId?: InputMaybe<Scalars['Int']['input']>
    email: Scalars['String']['input']
    firmName?: InputMaybe<Scalars['String']['input']>
    firstName: Scalars['String']['input']
    identNumber?: InputMaybe<Scalars['String']['input']>
    lastName: Scalars['String']['input']
    loginName: Scalars['String']['input']
    password: Scalars['String']['input']
    phoneNo: Scalars['String']['input']
    postCode?: InputMaybe<Scalars['String']['input']>
    street?: InputMaybe<Scalars['String']['input']>
    taxNumber?: InputMaybe<Scalars['String']['input']>
    town?: InputMaybe<Scalars['String']['input']>
    vatNumber?: InputMaybe<Scalars['String']['input']>
}

export type UserCreateResult = {
    __typename?: 'UserCreateResult'
    parameters: Array<ResultParameter>
}

export type UserUnblockInput = {
    registrationRID?: InputMaybe<Scalars['String']['input']>
}

export type UserUnblockResult = {
    __typename?: 'UserUnblockResult'
    parameters: Array<ResultParameter>
}

export type UserUpdateInput = {
    countryId?: InputMaybe<Scalars['Int']['input']>
    email: Scalars['String']['input']
    firstName: Scalars['String']['input']
    lastName: Scalars['String']['input']
    loginName: Scalars['String']['input']
    phoneNo: Scalars['String']['input']
    registrationRID: Scalars['String']['input']
}

export type UserUpdateResult = {
    __typename?: 'UserUpdateResult'
    parameters: Array<ResultParameter>
}

export class TypedDocumentString<TResult, TVariables>
    extends String
    implements DocumentTypeDecoration<TResult, TVariables>
{
    __apiType?: DocumentTypeDecoration<TResult, TVariables>['__apiType']

    constructor(
        private value: string,
        public __meta__?: Record<string, any>
    ) {
        super(value)
    }

    toString(): string & DocumentTypeDecoration<TResult, TVariables> {
        return this.value
    }
}
