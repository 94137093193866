import clsx from 'clsx'
import Link from 'next/link'
import { PropsWithChildren } from 'react'

interface Props {
    href: string
    className?: string
    color?: 'primary' | 'secondary'
    onClick?: () => void
}
export default function EshopLink({
    href,
    className,
    children,
    color = 'primary',
    onClick,
}: PropsWithChildren<Props>) {
    return (
        <Link
            href={href}
            className={clsx(
                color === 'primary' ? 'text-primary' : 'text-secondary',
                'underline',
                className
            )}
            onClick={onClick}
        >
            {children}
        </Link>
    )
}
